import React from 'react';
import { RulePreviewVisualStore } from '../../stores';
import Sider from 'antd/lib/layout/Sider';
import PreviewContent from '../../../common/components/PreviewContent';
import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite';

interface ResultsPreviewProps {
    store: RulePreviewVisualStore;
}

const ResultsPreview: React.FC<ResultsPreviewProps> = ({ store }) => {
    return (
        <Sider
            className="rule-document-preview-container"
            data-id="rule-preview-results-container"
            width={'100%'}
            style={{ background: '#F5F6F8', height: 'calc(100% - 82px)' }}
        >
            <div className="page-number-viewer" style={{ visibility: store.currentPage !== -1 ? 'visible' : 'hidden' }}>
                {store.currentPage + 1}
            </div>
            <div className={!store.selectedPackage ? 'empty-preview-title' : 'empty-preview-title hidden'}>
                Tap on a document name to get a result’s preview.
            </div>
            <div id="rule-document-preview-container" style={{ height: '100%', overflow: 'hidden' }}>
                <PreviewContent store={store} pageHeaderHeight={260} />
            </div>
            {!store.selectPackage ? (
                <div />
            ) : (
                <div className="rule-preview-pagination">
                    <Pagination
                        current={store!.currentPage + 1}
                        total={store!.totalPages}
                        defaultPageSize={1}
                        style={{ display: store.totalPages !== 0 ? 'block' : 'none' }}
                        onChange={p => store!.setCurrentPage(p - 1)}
                    />
                </div>
            )}
        </Sider>
    );
};

export default observer(ResultsPreview);
