import appClient from './AppClient';
import { UserBasicDetailsDto } from '../types';

export default class UsersService {
    async getUsersBasicDetails(): Promise<UserBasicDetailsDto[]> {
        const url = `${process.env.REACT_APP_USERS_URL}basic-details`;
        const resp = await appClient.get<UserBasicDetailsDto[]>(url);
        return resp.unwrapOr(Array<UserBasicDetailsDto>());
    }
}
