import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import { CreateEditConditionFormValues } from '../../types';
import { ConditionModel } from '../../models';
import { TagModel } from '../../../rules/models/TagModel';

interface Props {
    label: string;
    condition?: ConditionModel;
    store: ApplicationDefinitionConditionalEditStore;
    formFieldKey: keyof CreateEditConditionFormValues;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore;
}
const ConditionFormField: React.FC<Props> = ({
    label,
    formFieldKey,
    condition,
    store,
    applicationDefinitionEditStore
}) => {
    const validateConditionName = (_: unknown, value?: string) => {
        const conditionName = value?.trim();

        if (!conditionName || !conditionName.length) {
            return Promise.resolve();
        }

        const conditionExists = store.conditions.some(c => {
            if (condition) {
                return c.id !== condition.id && c.name.toLowerCase() === conditionName.toLowerCase();
            }

            return c.name === conditionName;
        });

        if (conditionExists) {
            return Promise.reject('Condition with such name already exists');
        }

        return Promise.resolve();
    };

    switch (formFieldKey) {
        case 'name':
            return (
                <Form.Item
                    name={formFieldKey}
                    label={label}
                    initialValue={condition ? condition.name : null}
                    rules={[
                        { required: true, message: 'Please enter condition name!' },
                        { validator: validateConditionName }
                    ]}
                >
                    <Input />
                </Form.Item>
            );
        case 'tagId':
            return (
                <Form.Item
                    name={formFieldKey}
                    label={label}
                    initialValue={condition ? condition.tagId : null}
                    rules={[{ required: true, message: 'Please select tag!' }]}
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select tag"
                        filterOption={(text, option) =>
                            option?.title && option.title.toLowerCase().includes(text.toLowerCase())
                        }
                    >
                        {applicationDefinitionEditStore.tagGroups.map(tagGroup => (
                            <Select.OptGroup key={tagGroup.id} label={tagGroup.name}>
                                {applicationDefinitionEditStore.ruleTags
                                    .filter(t => t.groupId === tagGroup.id)
                                    .filter((t): t is TagModel & { id: string } => !!t.id)
                                    .map(tag => (
                                        <Select.Option key={tag.id} value={tag.id} title={tag.name}>
                                            {tag.name}
                                        </Select.Option>
                                    ))}
                            </Select.OptGroup>
                        ))}
                    </Select>
                </Form.Item>
            );
        case 'searchText':
            return (
                <Form.Item
                    name={formFieldKey}
                    label={
                        <span>
                            {label + ' '}
                            <Tooltip
                                overlayInnerStyle={{ width: 450 }}
                                title={
                                    <div style={{ width: 450 }}>
                                        <span>
                                            Please list the keywords and phrases that, when found, should trigger the
                                            specified condition. Keep in mind the following syntax:
                                        </span>
                                        <ul>
                                            <li>For OR condition: Use &quot;word&quot; on a new line</li>
                                            <li>
                                                For AND condition: Use &quot;word&quot; AND &quot;word&quot; on the same
                                                line
                                            </li>
                                            <li>For word ending variations: Use &quot;word*&quot;</li>
                                        </ul>
                                    </div>
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    initialValue={condition ? condition.searchText : null}
                    rules={[{ required: true, message: 'Please specify words or phrases!' }]}
                >
                    <Input.TextArea rows={4} placeholder="Phrase 1" />
                </Form.Item>
            );
        case 'inputGroupId':
            return (
                <Form.Item
                    name={formFieldKey}
                    label={label}
                    initialValue={condition ? condition.inputGroupId : null}
                    rules={[{ required: true, message: 'Please select input group!' }]}
                >
                    <Select
                        allowClear
                        placeholder="Select input group"
                        options={store.inputGroups.map(inputGroup => ({
                            value: inputGroup.inputGroupId,
                            label: inputGroup.name
                        }))}
                    />
                </Form.Item>
            );
        default:
            return null;
    }
};

export default observer(ConditionFormField);
