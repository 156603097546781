import { Collapse } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { observer, useLocalStore } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import * as React from 'react';
import InstructWorkflowService from '../../../instruct_workflows/services/InstructWorkflowService';
import { InstructWorkflowTypeDto, InstructWorkflowDto } from '../../../instruct_workflows/types';
import PipelineStep from '../../../pipeline_base/models/PipelineStep';
import { OverridenParamsEditorEntry, Parameter } from '../../types';
import { PipelineVisualStore } from '../../stores';
import Checkbox from 'antd/lib/checkbox';
import { WorkflowParametersList } from './WorkflowParamsRenderer';
import './OverridableWorkflowParametersEditor.less';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

export const OverridableWorkflowParametersEditor: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = ({ step, form, store }) => {
    const workflowStore = useLocalStore(() => ({
        loading: false,
        service: new InstructWorkflowService(),
        workflowTypes: new Array<InstructWorkflowTypeDto>(),
        workflows: new Array<InstructWorkflowDto>(),
        async load() {
            if (!store.currentProject) {
                return;
            }

            runInAction(() => {
                workflowStore.loading = true;
            });

            const [workflowTypes, workflows] = await Promise.all([
                this.service.getWorkflowTypes(store.currentProject.id),
                this.service.getWorkflows(store.currentProject.id)
            ]);

            runInAction(() => {
                workflowStore.workflows = workflows;
                workflowStore.workflowTypes = workflowTypes;
                workflowStore.loading = false;
            });
        }
    }));

    // TODO: remove // eslint-disable-next-line react-hooks/exhaustive-deps and refactor dependencies
    const workflow = workflowStore.workflows.find(f => f.id === step.parameters.get('instruct_workflow_id'));
    const workflowType = workflowStore.workflowTypes.find(f => f.id === workflow?.workflowTypeId);

    React.useEffect(() => {
        workflowStore.load();
    }, [workflowStore]);

    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);

    const getOverriddenWorkflowParameters = React.useCallback(() => {
        const overriddenParams = step.parameters.get('overridden_workflow_parameters') ?? [];

        if (workflow && workflowType) {
            const workflowParameters = workflowType.inputs.filter(i => i.control === 'Text');

            const params = [
                ...workflowParameters.map(f => {
                    const inputValue = workflow.inputValues?.find(input => input.inputId === f.id)?.value;
                    const paramValue = overriddenParams[f.id] ?? inputValue;

                    return {
                        key: f.id,
                        name: f.name,
                        value: paramValue ?? f.defaultValue ?? null,
                        isCustom: false,
                        description: f.description
                    };
                }),
                ...Object.keys(overriddenParams)
                    .filter(key => !workflowParameters.some(f => f.id === key))
                    .map(key => ({
                        key: key,
                        name: '',
                        value: overriddenParams[key],
                        isCustom: true,
                        description: ''
                    }))
            ];
            return params;
        }
        return [];
    }, [step, workflow, workflowType]);

    const [overriddenWorkflowParameters, setOverriddenWorkflowParameters] = React.useState<
        OverridenParamsEditorEntry[]
    >([]);

    React.useEffect(() => {
        form.setFieldsValue({ overridden_workflow_parameters: overriddenWorkflowParameters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overriddenWorkflowParameters]);

    React.useEffect(() => {
        setOverriddenWorkflowParameters(getOverriddenWorkflowParameters());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflow, step, form]);

    React.useEffect(() => {
        const subject = store.formFieldsSubject.subscribe(({ changedFields }) => {
            if (changedFields) {
                const allFormValues = form.getFieldsValue();
                setOverriddenWorkflowParameters(allFormValues.overridden_workflow_parameters);
            }
        });

        return () => subject.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    const handleAddField = async () => {
        const newField = { key: '', name: '', value: '', isCustom: true, description: '' };
        const newParams = [...getOverriddenWorkflowParameters(), newField];
        form.setFieldsValue({ overridden_workflow_parameters: newParams });
        setOverriddenWorkflowParameters(newParams);
    };

    const handleRemoveField = (index: number) => {
        const newParams = (overriddenWorkflowParameters ?? []).filter((_, idx) => idx !== index);
        form.setFieldsValue({ overridden_workflow_parameters: newParams });
        setOverriddenWorkflowParameters(newParams);
    };

    const overrideFlagPanel = () => {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <Form.Item name="override_workflow_parameters" valuePropName="checked">
                    <label>
                        <Checkbox
                            style={{ marginLeft: 12 }}
                            defaultChecked={step.parameters.get('override_workflow_parameters')}
                        />
                    </label>
                </Form.Item>
            </div>
        );
    };

    const overrideEnabled = form.getFieldValue('override_workflow_parameters');

    React.useEffect(() => {
        if (overrideEnabled) {
            setOverriddenWorkflowParameters(getOverriddenWorkflowParameters());
        }
    }, [overrideEnabled, getOverriddenWorkflowParameters]);

    return (
        <div className="overridable-parameters-editor">
            <Collapse
                activeKey={openPanelKeys}
                onChange={key => setOpenPanelKeys(key as string[])}
                style={{ marginBottom: '18px' }}
            >
                <Collapse.Panel key="1" header="Workflow parameters" forceRender extra={overrideFlagPanel()}>
                    <WorkflowParametersList
                        overrideEnabled={overrideEnabled}
                        form={form}
                        overriddenWorkflowParameters={overriddenWorkflowParameters}
                        handleRemoveField={handleRemoveField}
                        handleAddField={handleAddField}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(OverridableWorkflowParametersEditor);
