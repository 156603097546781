import { Button, Modal } from 'antd';
import React from 'react';
import TableBlockPreview from '../TableBlockPreview';
import ReactJson from 'react-json-view';
import { addNewLines } from './Utils';
import { observer } from 'mobx-react-lite';

interface RowPreviewModalProps {
    isVisible: boolean;
    content: string | undefined;
    type: string;
    dialogWidth: string | number;
    onClose: () => void;
}

const RowPreviewModal: React.FC<RowPreviewModalProps> = ({ isVisible, content, type, dialogWidth, onClose }) => {
    const renderContent = () => {
        if (!content) {
            return null;
        }

        if (type === 'Table') {
            return <TableBlockPreview content={content} />;
        }

        try {
            const parsed = JSON.parse(content);
            return <ReactJson src={parsed} displayDataTypes={false} />;
        } catch {
            return addNewLines(content);
        }
    };

    return (
        <Modal
            centered
            width={dialogWidth}
            visible={isVisible}
            onCancel={onClose}
            footer={<Button onClick={onClose}>Close</Button>}
        >
            {renderContent()}
        </Modal>
    );
};

export default observer(RowPreviewModal);
