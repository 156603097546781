import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Table, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { Utils } from '../../../common/services/Utils';
import { OrderBy } from '../../../common/types';
import { HISTORY_PAGE_SIZE } from '../../stores/HistoryStore';
import BaseHistoryRecordModel from '../../models/BaseHistoryRecordModel';
import HistoryStore from '../../stores/HistoryStore';
import LayoutHeader from '../../../../components/LayoutHeader';
import HistoryRecordExpandedRow from './HistoryRecordExpandedRow';
import HistoryFilters from './HistoryFilters';

interface Props {
    store: HistoryStore;
}

const HistoryGrid: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        if (store.project) {
            store.setSearchOnlyProjectRelated(true);
            store.searchHistory();
        }

        return () => store.dispose();
    }, [store, store.project]);

    const getSorterOrder = React.useCallback(
        (columnKey: string) => {
            if (!store.orderBy || store.orderBy.field !== columnKey) {
                return;
            }

            return store.orderBy.direction === 'ASC' ? 'ascend' : 'descend';
        },
        [store.orderBy]
    );

    const columns: ColumnsType<BaseHistoryRecordModel> = React.useMemo(() => {
        return [
            {
                title: 'Data Object',
                dataIndex: 'objectLabel',
                key: 'objectLabel',
                width: '20%'
            },
            {
                title: 'Entity Name',
                dataIndex: 'entityName',
                key: 'entityName',
                width: '25%',
                render: (_: unknown, record: BaseHistoryRecordModel) => {
                    const nameField = record.metaFields.find(f => f.name === 'EntityName');

                    return nameField ? (nameField.value as string) : '';
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: '10%'
            },
            {
                title: 'Username',
                dataIndex: 'userName',
                key: 'userName',
                width: '25%'
            },
            {
                title: 'Date',
                dataIndex: 'createDate',
                key: 'createDate',
                width: '20%',
                sorter: true,
                sortOrder: getSorterOrder('createDate'),
                render: (createDate: string) => {
                    return {
                        children: Utils.formatDateStringShort(createDate)
                    };
                }
            }
        ];
    }, [getSorterOrder]);

    const pagination: TablePaginationConfig = {
        pageSize: HISTORY_PAGE_SIZE,
        total: store.total,
        current: store.page + 1,
        showSizeChanger: false,
        onChange: (page: number) => {
            store.setPage(page - 1);
            store.searchHistory();
        }
    };

    const onChange = (sorter: SorterResult<BaseHistoryRecordModel>) => {
        let orderBy: OrderBy | undefined;

        if (sorter.columnKey && sorter.order) {
            orderBy = {
                field: sorter.columnKey as string,
                direction: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            };
        }

        store.setOrderBy(orderBy);
        store.searchHistory();
    };

    return (
        <Layout>
            <LayoutHeader
                title="Change History"
                subtitle={Utils.getSubtitle(store.project)}
                helpMessage="Chronological tracking of project modifications for accountability and troubleshooting"
                helpMessageTooltipPlacement="leftBottom"
                buttons={[
                    <Button
                        key="clearFilters"
                        onClick={() => {
                            store.clearFilters();
                            store.performSearch();
                        }}
                        size="large"
                        className="light"
                    >
                        Clear filters
                    </Button>
                ]}
            />
            <Layout.Content>
                <HistoryFilters store={store} />
                <Table
                    scroll={{ y: 'calc(100vh - 300px)' }}
                    rowKey={r => r.id}
                    dataSource={store.historyRecords}
                    columns={columns}
                    loading={store.loading}
                    pagination={pagination}
                    onChange={(_, __, sorter: SorterResult<BaseHistoryRecordModel>) => onChange(sorter)}
                    expandable={{
                        expandRowByClick: true,
                        expandedRowRender: record => <HistoryRecordExpandedRow historyRecord={record} />,
                        expandIcon: ({ expanded, onExpand, record }) => (
                            <i
                                className="alpha-icon xs expand-row arrow-expand"
                                style={
                                    expanded
                                        ? {
                                              transform: 'rotate(90deg)',
                                              marginLeft: 0
                                          }
                                        : {}
                                }
                                onClick={e => onExpand(record, e)}
                            />
                        )
                    }}
                />
            </Layout.Content>
        </Layout>
    );
};

export default observer(HistoryGrid);
