import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { BindingGroupModel } from '../../models';
import './BindingGroupExtraRenderer.less';

interface Props {
    bindingGroup: BindingGroupModel;
}

const BindingGroupExtraRenderer: React.FC<Props> = ({ bindingGroup }) => {
    return (
        <div className="binding-group-extra-renderer">
            <Button
                type="link"
                size="small"
                onClick={e => {
                    e.stopPropagation();
                    bindingGroup.setEditable(!bindingGroup.editable);
                }}
            >
                <EditOutlined />
            </Button>

            <div onClick={e => e.stopPropagation()}>
                <Switch size="small" checked={bindingGroup.enabled} onChange={bindingGroup.setEnabled} />
            </div>
        </div>
    );
};

export default observer(BindingGroupExtraRenderer);
