import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Input, Button } from 'antd';
import { InputGroupModel } from '../../models';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';

interface Props {
    inputGroup: InputGroupModel;
    store: ApplicationDefinitionConditionalEditStore;
}

interface FormValues {
    name: string;
}

const EditBindingGroupDialog: React.FC<Props> = ({ inputGroup, store }) => {
    const [form] = Form.useForm<FormValues>();

    const formId = React.useMemo(() => `edit-binding-group-form-${inputGroup.inputGroupId}`, [inputGroup]);

    const onFinish = (formValues: FormValues) => {
        inputGroup.editableBindingGroup?.setName(formValues.name);
        store.setIsDataChanged(true);
        onClose();
    };

    const onClose = () => {
        inputGroup.editableBindingGroup?.setEditable(false);
    };

    const validateBindingGroupName = (_: unknown, value?: string) => {
        const bindingGroupName = value?.trim();

        if (!bindingGroupName || !bindingGroupName.length) {
            return Promise.resolve();
        }

        const bindingGroupExists = inputGroup.bindingGroups.some(
            s =>
                s.bindingGroupId !== inputGroup.editableBindingGroup?.bindingGroupId &&
                s.name.toLowerCase() === bindingGroupName.toLowerCase()
        );

        if (bindingGroupExists) {
            return Promise.reject('Section with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            className="edit-binding-group-dialog"
            title="Edit Section"
            visible={!!inputGroup.editableBindingGroup}
            width={600}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    form="edit-binding-group-form"
                    data-id="edit-binding-group-cancel"
                    key="edit-binding-group-cancel"
                    className="light"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    form={formId}
                    data-id="edit-binding-group"
                    key="edit-binding-group"
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            ]}
        >
            <Form id={formId} layout="vertical" form={form} onFinish={onFinish} preserve={false}>
                <Form.Item
                    name="name"
                    label="Section name"
                    initialValue={inputGroup.editableBindingGroup ? inputGroup.editableBindingGroup.name : ''}
                    rules={[
                        { required: true, message: 'Please enter section name!' },
                        { validator: validateBindingGroupName }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(EditBindingGroupDialog);
