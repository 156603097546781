import { message } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import { TestProjectChange } from '../../common/types';
import { SecurityService } from '../../common/services';
import { ProjectsRootVisualStore, RouterStore, ProjectsStore } from '../../common/stores';
import { TestProjectsNavigation } from '../routes';
import { TestProjectService } from '../services';
import {
    TestProject,
    TestProjectBaseline,
    TestProjectListModel,
    TestProjectRunResults,
    TestRunResultPreviewModel,
    TestRunTopicMetaField
} from '../types';
import TestProjectsVisualStore from './TestProjectsVisualStore';
import { PackageListModel } from '../misc';

export default class TestProjectDashboardStore {
    @observable
    currentTestProjectId: string | undefined;

    @observable
    isLoadingTestProject: boolean = false;

    @observable
    testProject: TestProjectListModel | undefined;

    @observable
    isLoadingBaselines: boolean = false;

    @observable
    testProjectBaselines: TestProjectBaseline[] = [];

    @observable
    isSubmittingBaseline: boolean = false;

    @observable
    testRunInProgress: boolean = false;

    @observable
    isLoadingTestRuns: boolean = false;

    @observable
    testRuns: TestProjectRunResults[] = [];

    @observable
    accessToken: string | undefined;

    @observable
    currentTestRunId: string | undefined;

    @observable
    currentTestRunResults: TestProjectRunResults | undefined;

    @observable
    testResultIsLoading: boolean = false;

    @observable
    previewWindow: Window | undefined | null;

    @observable
    deletingPackageId: string | null = null;

    @computed
    get packages() {
        return this.testProjectsStore.packages;
    }

    @computed
    get packageListItems() {
        if (!this.testProject || this.testProjectsStore.loadingPackages) {
            return [];
        }

        return this.testProject.packageIds.map(id => {
            return new PackageListModel(id, this.getPackageById(id)?.name ?? id);
        });
    }

    @computed
    get testRunsDisplayList() {
        let runs = [...this.testRuns];

        if (this.testRunInProgress || this.testProject?.isRunning === true) {
            runs = [
                { id: 'inProgress', runTime: 'Running...', testProjectId: this.currentTestProjectId ?? '', topics: [] },
                ...runs
            ];
        }

        return runs;
    }

    @computed
    get isLoadingUsers() {
        return this.testProjectsStore.isLoadingUsers;
    }

    @computed
    get isLoadingBaselinesScreenData() {
        return this.isLoadingBaselines || this.isLoadingTestProject || this.testProjectsStore.loadingPackages;
    }

    @computed
    get currentProject() {
        return this.projectsStore.currentProject;
    }

    @computed
    get projectIsLocked() {
        return this.testRunInProgress || this.testProject?.isRunning === true || this.isLoadingTestProject;
    }

    @computed
    get orderedTestProjectTopics() {
        return this.testProject?.topics.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    }

    constructor(
        private projectsStore: ProjectsRootVisualStore,
        private routerStore: RouterStore,
        private testProjectsStore: TestProjectsVisualStore,
        private service: TestProjectService,
        public readonly projectsBaseStore: ProjectsStore
    ) {
        this.testProjectsStore.testProjectChangesSubject.subscribe(this.handleTestProjectChanges);
    }

    @action.bound
    handleTestProjectChanges(tpChanges: TestProjectChange) {
        if (tpChanges.id !== this.currentTestProjectId || !this.testProject) {
            return;
        }
        runInAction(() => {
            this.testProject!.isRunning = tpChanges.isRunning;
        });

        if (tpChanges.isRunning === false) {
            console.log(tpChanges);
            this.loadTestRuns();
            this.setNewPackageIds(tpChanges.newPackageIds ?? []);
        }
    }

    @action.bound
    initAccessToken() {
        this.accessToken = encodeURIComponent(SecurityService.token);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    matchCurrentTestProjectData(params: any) {
        this.currentTestProjectId = params.testProjectId;
        this.currentTestRunId = params.runId;
    }

    @action.bound
    goBackToList() {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(TestProjectsNavigation.TestProjectsPage.replace(':projectId', this.currentProject.id));
    }

    @action.bound
    async setIsLoadingTestProject(isLoading: boolean) {
        this.isLoadingTestProject = isLoading;
    }

    @action.bound
    setTestProject(testProject: TestProject | undefined) {
        this.testProject = testProject;
    }

    @action.bound
    async loadTestProject() {
        if (!this.currentTestProjectId || !this.currentProject) {
            return;
        }

        this.setIsLoadingTestProject(true);

        try {
            const testProject = await this.service.getTestProject(this.currentProject.id, this.currentTestProjectId);
            this.setTestProject(testProject);
        } catch (err) {
            console.error(err);
            message.error('Failed to load test project');
        } finally {
            this.setIsLoadingTestProject(false);
        }
    }

    @action.bound
    getUserNameById(userId: string) {
        return this.testProjectsStore.getUserNameById(userId);
    }

    @action.bound
    goToBaselines() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        this.routerStore.push(
            TestProjectsNavigation.TestProjectBaselinesPage.replace(':projectId', this.currentProject.id).replace(
                ':testProjectId',
                this.currentTestProjectId
            )
        );
    }

    @action.bound
    goToDashboard() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        this.routerStore.push(
            TestProjectsNavigation.TestProjectDashboardPage.replace(':projectId', this.currentProject.id).replace(
                ':testProjectId',
                this.currentTestProjectId
            )
        );
    }

    @action.bound
    goToTestResults(runId: string) {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        this.routerStore.push(
            TestProjectsNavigation.TestProjectRunResultsPage.replace(':projectId', this.currentProject.id)
                .replace(':testProjectId', this.currentTestProjectId)
                .replace(':runId', runId)
        );
    }

    @action.bound
    setIsLoadingBaselines(isLoading: boolean) {
        this.isLoadingBaselines = isLoading;
    }

    @action.bound
    setTestProjectBaselines(baselines: TestProjectBaseline[]) {
        this.testProjectBaselines = baselines;
    }

    @action.bound
    async loadTestProjectBaselines() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }
        try {
            this.setIsLoadingBaselines(true);
            const baselines = await this.service.getTestProjectBaselines(
                this.currentProject.id,
                this.currentTestProjectId
            );
            this.setTestProjectBaselines(baselines);
        } catch (err) {
            console.error(err);
            message.error('Failed to load test project baselines');
        } finally {
            this.setIsLoadingBaselines(false);
        }
    }

    @action.bound
    getPackageById(packageId: string) {
        return this.testProjectsStore.getPackageById(packageId);
    }

    @action.bound
    async loadAllProjectPackages() {
        await this.testProjectsStore.loadAllProjectPackages();
    }

    @action.bound
    setIsSubmittingBaseline(isSubmitting: boolean) {
        this.isSubmittingBaseline = isSubmitting;
    }

    @action.bound
    insetBaseline(baseline: TestProjectBaseline) {
        if (baseline.testProjectId !== this.currentTestProjectId) {
            return;
        }

        let newBaselines = [...this.testProjectBaselines];
        newBaselines.push(baseline);
        this.setTestProjectBaselines(newBaselines);
    }

    @action.bound
    async createBaselineForTopic(topicId: string, packageId: string, value: string, fuzzy?: number) {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        let createdSuccessfully = false;
        try {
            this.setIsSubmittingBaseline(true);
            const resp = await this.service.createTestProjectBaseline(
                this.currentProject.id,
                this.currentTestProjectId,
                packageId,
                topicId,
                value,
                fuzzy
            );
            if (resp.isOk()) {
                createdSuccessfully = true;
                this.insetBaseline(resp.unwrapOr({} as TestProjectBaseline));
            } else {
                console.error('Failed to create baseline', resp.error);
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.setIsSubmittingBaseline(false);
        }

        return createdSuccessfully;
    }

    @action.bound
    async updateBaselineById(baselineId: string, value: string, fuzzy?: number) {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        let updatedSuccessfully = false;
        try {
            this.setIsSubmittingBaseline(true);
            const resp = await this.service.updateTestProjectBaseline(
                this.currentProject.id,
                this.currentTestProjectId,
                baselineId,
                value,
                fuzzy
            );
            if (resp.isOk()) {
                updatedSuccessfully = true;
            } else {
                console.error('Failed to update baseline', resp.error);
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.setIsSubmittingBaseline(false);
        }

        return updatedSuccessfully;
    }

    @action.bound
    async deletePackageFromTestProject(packageId: string, packageName: string) {
        if (!this.testProject || !this.currentProject) {
            this.deletingPackageId = null;
            return;
        }

        try {
            this.deletingPackageId = packageId;

            const testProject = { ...this.testProject };

            testProject.packageIds = testProject.packageIds.filter(id => id !== packageId);

            const packageDeleteResp = await this.service.updateTestProjectPackages(
                this.currentProject.id,
                testProject.id,
                testProject.packageIds
            );

            if (packageDeleteResp.isOk()) {
                message.success(`Document "${packageName}" successfully removed from the test project`);
                this.setTestProject(testProject);
            } else {
                message.error(`Failed to remove document "${packageName}" from the test project`);
            }
        } finally {
            this.deletingPackageId = null;
        }
    }

    @action.bound
    async runTestProject() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        try {
            this.setTestRunInProgress(true);
            const resp = await this.service.runTestProject(this.currentProject.id, this.currentTestProjectId);
            if (resp.isOk()) {
                message.success('Test project run started successfully');
                runInAction(() => {
                    this.testProject!.isRunning = true;
                });
            } else {
                message.error('Failed to run test project');
            }
        } catch (err) {
            console.error(err);
            message.error('Failed to run test project');
        } finally {
            this.setTestRunInProgress(false);
        }
    }

    @action.bound
    async abortTestProjectRun() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        try {
            const resp = await this.service.abortTestProjectRun(this.currentProject.id, this.currentTestProjectId);
            if (resp.isOk()) {
                message.success('Test project run abort initiated successfully');
            } else {
                message.error('Failed to abort test project run');
            }
        } catch (err) {
            console.error(err);
            message.error('Failed to abort test project run');
        } finally {
            this.setTestRunInProgress(false);
        }
    }

    @action.bound
    async cleanTestProject() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }
        try {
            const resp = await this.service.cleanTestProject(this.currentProject.id, this.currentTestProjectId);
            if (resp.isOk()) {
                message.success('Test project has been cleaned');
            } else {
                message.error('Failed to clean test project');
            }
        } catch (err) {
            console.error(err);
            message.error('Failed to clean test project');
        } finally {
            await this.loadTestProject();
        }
    }

    @action.bound
    setTestRunInProgress(isInProgress: boolean) {
        this.testRunInProgress = isInProgress;
    }

    @action.bound
    setIsLoadingTestRuns(isLoading: boolean) {
        this.isLoadingTestRuns = isLoading;
    }

    @action.bound
    setTestRuns(testRuns: TestProjectRunResults[]) {
        this.testRuns = testRuns;
    }

    @action.bound
    async loadTestRuns() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        try {
            this.setIsLoadingTestRuns(true);
            const testRuns = await this.service.getTestProjectRunResults(
                this.currentProject.id,
                this.currentTestProjectId
            );
            this.setTestRuns(testRuns);
        } catch (err) {
            console.error(err);
            message.error('Failed to load test runs');
        } finally {
            this.setIsLoadingTestRuns(false);
        }
    }

    @action.bound
    async exportTestProjectBaselines() {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        await this.service.exportTestProjectBaselines(this.currentProject.id, this.currentTestProjectId);
    }

    @action.bound
    async exportTestProjectRunToExcel(testRunId: string) {
        if (!this.currentProject || !this.currentTestProjectId) {
            return;
        }

        await this.service.exportTestProjectRunResuts(this.currentProject.id, this.currentTestProjectId, testRunId);
    }

    @action.bound
    setCurrentTestRunResults(testRunResults: TestProjectRunResults | undefined) {
        this.currentTestRunResults = testRunResults;
    }

    @action.bound
    async loadTestRunResults() {
        if (!this.currentProject || !this.currentTestProjectId || !this.currentTestRunId) {
            return;
        }

        try {
            this.setTestResultIsLoading(true);
            const testRunResults = await this.service.getTestRunResultById(
                this.currentProject.id,
                this.currentTestProjectId,
                this.currentTestRunId
            );
            this.setCurrentTestRunResults(testRunResults);
        } catch (err) {
            console.error(err);
            message.error('Failed to load test run results');
        } finally {
            this.setTestResultIsLoading(false);
        }
    }

    @action.bound
    async updateBaselineFromTestRun(baselineId: string, value: string) {
        if (!this.currentProject || !this.currentTestProjectId || !this.currentTestRunId) {
            return;
        }

        try {
            const resp = await this.service.updateBaselineFromTestRun(
                this.currentProject.id,
                this.currentTestProjectId,
                this.currentTestRunId,
                baselineId,
                value
            );

            if (resp.isOk()) {
                this.updateTopicModifiedValue(baselineId, value);
                message.success('Baseline updated successfully');
            } else {
                message.error('Failed to update baseline from test run');
            }
        } catch (ex) {
            message.error('Failed to update baseline from test run');
            console.error(ex);
        }
    }

    @action.bound
    updateTopicModifiedValue(baselineId: string, value: string) {
        if (!this.currentTestRunResults) {
            return;
        }

        const indexOfTopic = this.currentTestRunResults.topics.findIndex(t => t.baselineId === baselineId);

        if (indexOfTopic === -1) {
            return;
        }

        this.currentTestRunResults.topics[indexOfTopic].modifiedBaseline = value;
    }

    @action.bound
    setTestResultIsLoading(isLoading: boolean) {
        this.testResultIsLoading = isLoading;
    }

    @action.bound
    openPreviewTopicResultPreviewWindow(
        packageId: string | null,
        topicId: string,
        blocks: TestRunTopicMetaField[] = []
    ) {
        if (!this.currentProject || !packageId) {
            return;
        }

        const url = `${window.location.origin}/projects/${this.currentProject.id}/test-project/${this.currentTestProjectId}/preview`;
        const previewData: TestRunResultPreviewModel = {
            packageId,
            topicId,
            blocks
        };

        localStorage.setItem('test-project-preview', JSON.stringify(previewData));

        if (!this.previewWindow || this.previewWindow.closed) {
            this.previewWindow = window.open(
                url,
                'winPop',
                'location=yes,height=700,width=1100,scrollbars=yes,status=yes'
            );
        } else {
            this.previewWindow.dispatchEvent(new Event('storage'));
            this.previewWindow.focus();
        }
    }

    @action.bound
    removePackageFromNewPackages(packageId: string) {
        if (!this.testProject || !this.testProject.newPackageIds) {
            return;
        }

        this.testProject.newPackageIds = this.testProject.newPackageIds.filter(id => id !== packageId);
    }

    @action.bound
    resetNewPackages() {
        if (!this.testProject) {
            return;
        }

        this.testProject.newPackageIds = [];
    }

    @action.bound
    setNewPackageIds(newPackageIds: string[]) {
        if (!this.testProject) {
            return;
        }

        this.testProject.newPackageIds = newPackageIds;
    }
}
