import ParametersInputEditor from './ParametersInputEditor';
import ParametersDropdownEditor from './ParametersDropdownEditor';
import ParametersCheckboxEditor from './ParametersCheckboxEditor';
import ParametersModelEditor from './ParametersModelEditor';
import ParametersLabelsEditor from './ParametersLabelsEditor';
import ParametersIotaConnectionsPackageSelector from './ParametersIotaConnectionsPackageSelector';
import ParametersIotaConnectionSelector from './ParametersIotaConnectionSelector';
import ParametersIotaConnectionFieldSelector from './ParametersIotaConnectionFieldSelector';
import ParametersCodeBlockEditor from './ParametersCodeBlockEditor';
import ParametersConnectionIdSelector from './ParametersConnectionIdSelector';
import ParametersSqlQueryEditor from './ParametersSqlQueryEditor';
import { ParametersNERModelEditor } from './ParametersNERModelEditor';
import ParametersTextAreaEditor from './ParametersTextAreaEditor';
import ParametersRulePatternEditor from './ParametersRulePatternEditor';
import ParametersPipelineSelect from './ParametersPipelineSelect';
import ParametersRegexEditor from './ParametersRegexEditor';
import {
    ParametersCustomEmbeddingModelSelect,
    ParametersCustomCrossEncoderModelSelect
} from './ParametersCustomModelSelect';
import { ParametersInstructWorkflowSelect, ParametersOutputSchemeSelect } from './ParametersInstructWorkflow';
import OverridableWorkflowParametersEditor from './OverridableWorkflowParametersEditor/OverridableWorkflowParametersEditor';

export const Registry = {
    input: ParametersInputEditor,
    dropdown: ParametersDropdownEditor,
    checkbox: ParametersCheckboxEditor,
    inferenceModelSelect: ParametersModelEditor,
    labelsSelect: ParametersLabelsEditor,
    iotaConnectionPackageSelect: ParametersIotaConnectionsPackageSelector,
    iotaConnectionSelect: ParametersIotaConnectionSelector,
    iotaConnectionFieldSelect: ParametersIotaConnectionFieldSelector,
    codeEditor: ParametersCodeBlockEditor,
    connectionIdSelect: ParametersConnectionIdSelector,
    sqlQueryEditor: ParametersSqlQueryEditor,
    NERModelSelect: ParametersNERModelEditor,
    textArea: ParametersTextAreaEditor,
    rulePatternEditor: ParametersRulePatternEditor,
    pipelineSelect: ParametersPipelineSelect,
    regexEditor: ParametersRegexEditor,
    customEmbeddingModelSelect: ParametersCustomEmbeddingModelSelect,
    customCrossEncoderModelSelect: ParametersCustomCrossEncoderModelSelect,
    instructWorkflowSelect: ParametersInstructWorkflowSelect,
    instructWorkflowOutputSchemeSelect: ParametersOutputSchemeSelect,
    overridableWorkflowParametersEditor: OverridableWorkflowParametersEditor
};
