import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import React from 'react';
import { Utils } from '../../../../common/services/Utils';
import { addNewLines } from '../Utils';
import { PreviewIndexToken, PreviewResultEntry } from '../../../types';

type TextItemTitleProps = {
    entry: PreviewResultEntry;
    jsonObjPreview: JSX.Element | undefined;
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void;
};

export const createTextItemTitle = ({ entry, jsonObjPreview, openRowPreviewDialog }: TextItemTitleProps) => {
    return (
        <Popover
            content={
                <div style={{ width: 500, maxHeight: 500, overflow: 'auto' }}>
                    {jsonObjPreview != null ? jsonObjPreview : addNewLines(entry.field.normalizedText)}
                </div>
            }
            autoAdjustOverflow
            placement="bottomRight"
        >
            <Tooltip title="Copy value to clipboard">
                <Button
                    style={{ verticalAlign: 'middle', padding: '0 6px' }}
                    size="small"
                    shape="round"
                    icon={<CopyOutlined style={{ fontSize: 12, verticalAlign: 'baseline' }} />}
                    type="link"
                    onClick={() => Utils.copyValToClipboard(entry.field.normalizedText)}
                />
            </Tooltip>
            <Button
                style={{
                    verticalAlign: 'middle',
                    padding: '0 6px'
                }}
                size="small"
                shape="round"
                type="link"
                icon={<EyeOutlined />}
                onClick={() => openRowPreviewDialog(entry.field.normalizedText, entry.resultTokens)}
            />
            <span
                style={{
                    maxWidth: 270,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                }}
            >
                {addNewLines(entry.field.normalizedText)}
            </span>
        </Popover>
    );
};
