import ReactJson from 'react-json-view';
import * as React from 'react';
import { PackageLine, Project } from '../../../common/models';
import { PackageTag } from '../../types';
import { Tag, Tooltip } from 'antd';
import { RulePreviewVisualStore } from '../../stores';
import { CommentOutlined } from '@ant-design/icons';

export const renderJsonPreview = (val: string) => {
    try {
        const parsed = JSON.parse(val);

        if (typeof parsed !== 'object') {
            return <span>{val}</span>;
        }

        return <ReactJson src={parsed} displayDataTypes={false} />;
    } catch {
        return <span>{val}</span>;
    }
};

export const renderFieldValues = (line: PackageLine, fieldKey: string) => {
    let item = line[fieldKey];
    if (typeof item === 'string' || typeof item === 'number') {
        return (
            <span data-id={`fields-${line.packageId}-${line.rowId}}-${fieldKey}`}>
                <span>
                    {fieldKey}: {item}
                </span>
            </span>
        );
    } else if (fieldKey === 'tags') {
        return (
            <span data-id={`fields-${line.packageId}-${line.rowId}}-${fieldKey}`}>
                {fieldKey}:{' '}
                {item &&
                    item.map((t: PackageTag) => (
                        <span key={`fields-${line.packageId}-${line.rowId}}-${fieldKey}-${t.tagId}`}>
                            {t.values.map((v: string) => (
                                <Tag key={`${line.rowId}-tag-${v}`}>{v}</Tag>
                            ))}
                        </span>
                    ))}
            </span>
        );
    }

    return null;
};

export const renderAskAlphaIcon = (
    packageId: string,
    project: Project | null | undefined,
    store: RulePreviewVisualStore
) => {
    if (!project?.smartIndexSettings?.enabled) {
        return null;
    }

    const onClick = () => {
        const existingInteractiveLabelsWindow = store.interactiveLabelsWindows.get(packageId);

        if (existingInteractiveLabelsWindow && !existingInteractiveLabelsWindow.closed) {
            existingInteractiveLabelsWindow.focus();
            return;
        }

        const url = `${window.location.origin}/projects/${project?.id}/interactivelabels/0/0?package_id=${packageId}&external_window_mode=true&open_ask_alpha=true`;
        const newInteractiveLabelsWindow = window.open(url, `interactive-labels-${packageId}`, 'height=900,width=1280');

        if (newInteractiveLabelsWindow) {
            newInteractiveLabelsWindow.focus();
            store.addInteractiveLabelsWindow(packageId, newInteractiveLabelsWindow);
        }
    };

    return (
        <Tooltip title="Ask Alpha">
            <CommentOutlined className="rules-ask-alpha-icon" onClick={onClick} />
        </Tooltip>
    );
};
