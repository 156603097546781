import { Popover } from 'antd';
import React from 'react';
import { PreviewResultEntry } from '../../../types';

type RootEntryChildItemProps = {
    entry: PreviewResultEntry;
    jsonObjPreview: JSX.Element | undefined;
    selectedRowData: { rowId: undefined; pkgId: undefined };
};

export const createRootEntryChildItem = ({ entry, jsonObjPreview, selectedRowData }: RootEntryChildItemProps) => {
    return (
        <Popover
            content={
                <div style={{ width: 500, maxHeight: 400, overflow: 'auto' }}>
                    {jsonObjPreview != null ? jsonObjPreview : entry.field.normalizedText}
                </div>
            }
            autoAdjustOverflow
            data-id={`entry-${entry.field.packageId}-${entry.field.rowId}`}
            placement="bottomRight"
        >
            <span
                className="tree-selectable-node"
                data-rowid={entry.field.rowId}
                data-pkgid={entry.field.packageId}
                data-selected={
                    selectedRowData &&
                    selectedRowData.rowId === entry.field.rowId &&
                    selectedRowData.pkgId === entry.field.packageId
                        ? 'true'
                        : 'false'
                }
            >
                {entry.field.normalizedText}
            </span>
        </Popover>
    );
};
