import { Button } from 'antd';
import React from 'react';
import { PreviewIndexToken } from '../../../types';
import { PackageLine } from '../../../../common/models';
import { EyeOutlined } from '@ant-design/icons';

type ExtraDataItemChildChildrenTitleProps = {
    field: PackageLine;
    resultToken: PreviewIndexToken;
    extraDataItemKey: string;
    extraDataKey: string;
    extraDataItemChildrenKey: string;
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void;
};

export const createExtraDataItemChildChildrenTitle = ({
    field,
    resultToken,
    extraDataItemKey,
    extraDataKey,
    extraDataItemChildrenKey,
    openRowPreviewDialog
}: ExtraDataItemChildChildrenTitleProps) => {
    return (
        <span
            data-id={`extra-${field.packageId}-${field.rowId}-${resultToken.token.token}-${extraDataItemKey}-${extraDataKey}-${extraDataItemChildrenKey}`}
            style={{
                maxWidth: 280,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
        >
            {extraDataItemChildrenKey}:{' '}
            {!Array.isArray(resultToken.extraData[extraDataItemKey][extraDataKey][extraDataItemChildrenKey]) ? (
                resultToken.extraData[extraDataItemKey][extraDataKey][extraDataItemChildrenKey]
            ) : (
                <>
                    <Button
                        style={{
                            verticalAlign: 'middle',
                            padding: '0 6px'
                        }}
                        size="small"
                        shape="round"
                        type="link"
                        icon={<EyeOutlined />}
                        onClick={event => {
                            if (
                                !resultToken.extraData[extraDataItemKey][extraDataKey] ||
                                !resultToken.extraData[extraDataItemKey][extraDataKey][extraDataItemChildrenKey]
                            ) {
                                return;
                            }

                            console.log(extraDataItemKey, ',', extraDataKey, ',', extraDataItemChildrenKey);

                            const target = event.currentTarget as Element;
                            const content = target.nextSibling as Element;

                            if (!content?.textContent) {
                                return;
                            }

                            openRowPreviewDialog(content.textContent, []);
                        }}
                    />
                    <span
                        style={{
                            maxWidth: 270,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle'
                        }}
                    >
                        {JSON.stringify(
                            resultToken.extraData[extraDataItemKey][extraDataKey][extraDataItemChildrenKey]
                        )}
                    </span>
                </>
            )}
        </span>
    );
};
