import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Form } from 'antd';
import { InputGroupModel, InputModel, BindingGroupModel } from '../../models';
import { InputGroupActionsRenderer, EditBindingGroupDialog, BindingGroupExtraRenderer } from '..';
import { InputBindingFormField } from '../../types';
import {
    ApplicationDefinitionConditionalEditStore,
    ApplicationDefinitionConditionalBindingPreviewStore
} from '../../stores';
import { BindingType } from '../../../iota_applications/types';
import { ApplicationInputBindingsRenderer } from '../../../iota_applications/components';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import './InputGroupRenderer.less';

const formItemLayout = {
    labelCol: {
        xs: { span: 5 },
        sm: { span: 7 }
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 }
    }
};

type FormValues = Record<string, InputBindingFormField[]>;

interface Props {
    inputGroup: InputGroupModel;
    store: ApplicationDefinitionConditionalEditStore;
    previewStore: ApplicationDefinitionConditionalBindingPreviewStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore;
}

const InputGroupRenderer: React.FC<Props> = ({ inputGroup, store, previewStore, applicationDefinitionEditStore }) => {
    const [form] = Form.useForm<FormValues>();

    const formId = React.useMemo(() => `input-group-form-${inputGroup.inputGroupId}`, [inputGroup]);

    const onValuesChange = (formValues: FormValues) => {
        Object.keys(formValues).forEach(inputId => handleInputValuesChange(inputId));
    };

    const handleInputValuesChange = (inputId: string) => {
        const fields: InputBindingFormField[] = form.getFieldValue(inputId);
        const input = inputGroup.getInputById(inputId);

        if (!input) {
            return;
        }

        const bindings = fields.map(field => ({
            ...field,
            value:
                field.type === BindingType.tag && typeof field.value === 'string'
                    ? applicationDefinitionEditStore.getTagIdByName(field.value)
                    : field.value
        }));

        input.updateBindings(bindings);
        store.setIsDataChanged(true);
    };

    const getInitialValues = () => {
        return inputGroup.bindingGroups.reduce<FormValues>((acc, bindingGroup) => {
            const setInitialInputValues = (group: BindingGroupModel) => {
                group.inputs.forEach(input => {
                    acc[input.inputId] = input.bindings.map(binding => ({
                        value:
                            binding.type === BindingType.tag && typeof binding.value === 'string'
                                ? applicationDefinitionEditStore.getTagById(binding.value)
                                : binding.value,
                        type: binding.type
                    }));
                });

                group.childBindingGroups.forEach(setInitialInputValues);
            };

            setInitialInputValues(bindingGroup);
            return acc;
        }, {});
    };

    const renderInputBindings = (input: InputModel, index: number) => (
        <ApplicationInputBindingsRenderer
            key={input.meta.id}
            store={applicationDefinitionEditStore}
            form={form}
            inputFormKey={input.inputId}
            input={input.meta}
            bindings={input.bindings}
            index={index}
            handleInputValuesChange={handleInputValuesChange}
            previewInput={(_: string, bindingIndex: number) =>
                previewStore.previewInput(inputGroup.inputGroupId, { ...input.bindings[bindingIndex] }, bindingIndex)
            }
        />
    );

    const renderGroupBindings = (bindingGroup: BindingGroupModel) => {
        if (!bindingGroup.childBindingGroups.length) {
            return <>{bindingGroup.inputs.map((input, index) => renderInputBindings(input, index))}</>;
        }

        return (
            <Collapse activeKey={bindingGroup.openChildBindingGroups} onChange={bindingGroup.setOpenChildBindingGroups}>
                {bindingGroup.childBindingGroups.map(group => (
                    <Collapse.Panel key={group.bindingGroupId} header={group.name}>
                        {group.inputs.map((input, index) => renderInputBindings(input, index))}

                        {group.childBindingGroups.map(childGroup => (
                            <Collapse
                                key={childGroup.bindingGroupId}
                                activeKey={childGroup.openChildBindingGroups}
                                onChange={childGroup.setOpenChildBindingGroups}
                                style={{ marginBottom: 15 }}
                            >
                                <Collapse.Panel key={group.bindingGroupId} header={childGroup.name}>
                                    {childGroup.inputs.map((input, index) => renderInputBindings(input, index))}
                                </Collapse.Panel>
                            </Collapse>
                        ))}
                    </Collapse.Panel>
                ))}
            </Collapse>
        );
    };

    return (
        <div className="input-group-renderer">
            <EditBindingGroupDialog inputGroup={inputGroup} store={store} />

            <InputGroupActionsRenderer inputGroup={inputGroup} store={store} previewStore={previewStore} />

            <Form
                id={formId}
                form={form}
                initialValues={getInitialValues()}
                onValuesChange={onValuesChange}
                preserve={false}
                {...formItemLayout}
            >
                <Collapse activeKey={inputGroup.openBindingGroupIds} onChange={inputGroup.setOpenBindingGroupIds}>
                    {inputGroup.bindingGroups.map(bindingGroup => (
                        <Collapse.Panel
                            className={`input-group-section ${!bindingGroup.enabled ? 'disabled' : ''}`}
                            key={bindingGroup.bindingGroupId}
                            header={bindingGroup.name}
                            extra={<BindingGroupExtraRenderer bindingGroup={bindingGroup} />}
                        >
                            {renderGroupBindings(bindingGroup)}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Form>
        </div>
    );
};

export default observer(InputGroupRenderer);
