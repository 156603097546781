import { execQuery } from '../../common/services/DataService';
import { gql } from 'apollo-boost';
import { SearchSessionsRequest, SessionResult } from '../../common/types';
import { SessionsResponse, ApplicationNamesResult, ApplicationSessionPayloadsResult } from '../types';
import { SessionModelsResult, SessionModelResult } from '../types';
import { UserBasicDetailsDto } from '../../common/types';

export default class SessionsServce {
    async searchSessions(request: SearchSessionsRequest, users: UserBasicDetailsDto[]): Promise<SessionsResponse> {
        let {
            projectId,
            search,
            pageSize,
            page,
            fieldsSearch,
            dates,
            appName,
            sortDirection,
            currentSortedField,
            userName
        } = request;

        if (!fieldsSearch) {
            fieldsSearch = {};
        }

        if (dates.length) {
            fieldsSearch.dateFilter = { dates: { first: dates[0], second: dates[1] } };
        }

        if (appName) {
            fieldsSearch.appFilter = { appName: appName };
        }

        if (userName) {
            fieldsSearch.userFilter = { userName: userName };
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var result = await execQuery<any>({
            /* eslint-disable max-len */
            query: gql`
                query searchSessionsForProject(
                    $projId: String!
                    $searchTerm: String
                    $pageSize: Int!
                    $page: Int = 0
                    $fieldsSearch: Json = null
                    $sortDirection: String
                    $currentSortedField: String
                ) {
                    searchSessions(
                        projId: $projId
                        searchTerm: $searchTerm
                        pageSize: $pageSize
                        page: $page
                        fieldsSearch: $fieldsSearch
                        sortDirection: $sortDirection
                        currentSortedField: $currentSortedField
                    ) {
                        result {
                            id
                            user
                            packageId
                            runtimeSessionId
                            created
                            updated
                            applicationDefinitionId
                            applicationName
                            packageName
                            error
                            userId
                        }
                        pageInfo {
                            total
                        }
                    }
                }
            `,
            variables: {
                projId: projectId,
                searchTerm: !search || search.length === 0 ? null : search,
                pageSize,
                page: page || 0,
                fieldsSearch: fieldsSearch || null,
                sortDirection: sortDirection,
                currentSortedField: currentSortedField
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.searchSessions.result) {
            if (result.errors) {
                throw new Error(result.errors!.toString());
            }

            return new SessionsResponse();
        }

        var response = new SessionsResponse();
        response.total = result.data.searchSessions.pageInfo.total;
        response.lines = result.data.searchSessions.result;
        response.lines.forEach(r => {
            r.created = new Date(r.created).toUTCString();
            r.updated = new Date(r.created).toUTCString();
            r.user = users.find(u => u.id === r.userId)?.userName || '';
        });
        return response;
    }

    async getSessions(projectId: string): Promise<SessionResult[]> {
        const result = await execQuery<SessionModelsResult>({
            /* eslint-disable max-len */
            query: gql`
                query getSessionModels($projectId: String!) {
                    getSessionModels(projectId: $projectId) {
                        id
                        packageId
                        runtimeSessionId
                        created
                        updated
                        applicationDefinitionId
                    }
                }
            `,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getSessionModels) {
            return result.data.getSessionModels;
        } else {
            return [];
        }
    }

    async getSessionById(sessionId: string): Promise<SessionResult | null> {
        const result = await execQuery<SessionModelResult>({
            /* eslint-disable max-len */
            query: gql`
                query getSessionModelById($sessionId: String!) {
                    getSessionModelById(sessionId: $sessionId) {
                        id
                        packageId
                        runtimeSessionId
                        created
                        updated
                        applicationDefinitionId
                        applicationName
                        packageName
                        error
                    }
                }
            `,
            variables: {
                sessionId: sessionId
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getSessionModelById?.length) {
            return result.data.getSessionModelById[0];
        }

        return null;
    }

    async getApplicationNames(projectId?: string) {
        var result = await execQuery<ApplicationNamesResult>({
            query: gql`
                query applicationNames($projectId: String) {
                    applicationNames(projectId: $projectId) {
                        applicationName
                    }
                }
            `,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.applicationNames.map(x => x.applicationName);
    }

    async getApplicationSessionPayloads(sessionId: string) {
        var result = await execQuery<ApplicationSessionPayloadsResult>({
            query: gql`
                query getSessionPayloads($sessionId: String) {
                    getSessionPayloads(sessionId: $sessionId) {
                        id
                        sessionId
                        created
                        data
                    }
                }
            `,
            variables: {
                sessionId: sessionId
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.getSessionPayloads || [];
    }
}
