import { RootStores } from '../../common/stores';
import IotaAuditVisualStore from './IotaAuditVisualStore';
import { STORE_IOTA_AUDIT } from '../constants';
import { STORE_PROJECTS_ROOT_UI, STORE_USERS } from '../../common/constants';
import IotaAuditService from '../services/IotaAuditService';
export { default as IotaAuditVisualStore } from './IotaAuditVisualStore';

export function injectIotaAuditStores(obj: RootStores) {
    const service = new IotaAuditService();

    obj[STORE_IOTA_AUDIT] = new IotaAuditVisualStore(obj[STORE_PROJECTS_ROOT_UI], service, obj[STORE_USERS]);
}

export type IotaSessionsStores = {
    [STORE_IOTA_AUDIT]?: IotaAuditVisualStore;
};
