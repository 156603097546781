import { observable, action } from 'mobx';
import { ConditionDto, InputGroupDto, BindingGroupDto } from '../../application_definition_conditional/types';
import { ApplicationCapabilities } from '.';
import InputBinding from './InputBinding';

export type ApplicationDefinitionType = 'ApplicationDefinition' | 'ApplicationDefinitionConditional';

export interface ApplicationDefinitionBaseDto {
    id: string;
    type: ApplicationDefinitionType;
    name: string;
    applicationId: string;
    workflowId: string;
    projectId: string;
    extension: string;
    state: string;
    meta: string;
    settings?: string;
    iconFileId?: string;
    settingValues?: { [id: string]: unknown };
    iconUrl?: string;
    capabilities?: ApplicationCapabilities;
    lastUpdatedBy?: string;
    lastUpdatedTime?: string;
}

export interface ApplicationDefinitionDto extends ApplicationDefinitionBaseDto {
    bindings?: InputBinding[];
}

export interface ApplicationDefinitionConditionalDto extends ApplicationDefinitionBaseDto {
    conditions: ConditionDto[];
    inputGroups: InputGroupDto[];
}

export abstract class ApplicationDefinitionBase implements ApplicationDefinitionBaseDto {
    @observable
    state: string;

    @observable
    lastUpdatedBy?: string;

    @observable
    lastUpdatedTime?: string;

    public id: string;
    public type: ApplicationDefinitionType;
    public name: string;
    public applicationId: string;
    public workflowId: string;
    public projectId: string;
    public extension: string;
    public meta: string;
    public settings?: string;
    public iconFileId?: string;
    public settingValues?: { [id: string]: unknown };
    public iconUrl?: string;
    public capabilities?: ApplicationCapabilities;

    constructor(data: ApplicationDefinitionBaseDto) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;
        this.applicationId = data.applicationId;
        this.workflowId = data.workflowId;
        this.projectId = data.projectId;
        this.extension = data.extension;
        this.state = data.state;
        this.meta = data.meta;
        this.settings = data.settings;
        this.iconFileId = data.iconFileId;
        this.settingValues = data.settingValues;
        this.iconUrl = data.iconUrl;
        this.capabilities = data.capabilities;
        this.lastUpdatedBy = data.lastUpdatedBy;
        this.lastUpdatedTime = data.lastUpdatedTime;
    }

    @action
    setState(state: string) {
        this.state = state;
    }

    @action.bound
    setLastUpdatedInfo(lastUpdatedBy?: string, lastUpdatedTime?: string) {
        this.lastUpdatedBy = lastUpdatedBy;

        // I <3 JS. If we don't wrap this in a setTimeout, save changes button will be disabled.
        setTimeout(() => {
            this.lastUpdatedTime = lastUpdatedTime;
        }, 0);
    }
}

export class ApplicationDefinition extends ApplicationDefinitionBase implements ApplicationDefinitionDto {
    public bindings: InputBinding[] = [];

    constructor(data: ApplicationDefinitionDto) {
        super(data);

        this.bindings = data.bindings ?? [];
    }
}

export class ApplicationDefinitionConditional
    extends ApplicationDefinitionBase
    implements ApplicationDefinitionConditionalDto
{
    public conditions: ConditionDto[] = [];
    public inputGroups: InputGroupDto[] = [];

    get allInputGroupBindings() {
        return this.inputGroups.reduce(
            (acc, inputGroup) => [...acc, ...ApplicationDefinitionConditional.getInputGroupBindings(inputGroup)],
            []
        );
    }

    constructor(data: ApplicationDefinitionConditionalDto) {
        super(data);

        this.conditions = data.conditions;
        this.inputGroups = data.inputGroups;
    }

    static getInputGroupBindings(inputGroup: InputGroupDto) {
        return inputGroup.bindingGroups.reduce<InputBinding[]>(
            (acc, bindingGroup) => [...acc, ...ApplicationDefinitionConditional.getAllBindings(bindingGroup)],
            []
        );
    }

    static getAllBindings(bindingGroup: BindingGroupDto): InputBinding[] {
        let bindings: InputBinding[] = [...bindingGroup.bindings];

        bindingGroup.childBindingGroups.forEach((childGroup: BindingGroupDto) => {
            bindings = [...bindings, ...ApplicationDefinitionConditional.getAllBindings(childGroup)];
        });

        return bindings;
    }
}
