import * as React from 'react';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { inject } from 'mobx-react';
import { Package } from '../../common/models';
import { RootStores } from '../../common/stores';
import { STORE_PROJECTS } from '../../common/constants';
import { ALPHA_CONFIG_VARIABLES } from '../../administration/types/AlphaConfigModel';

type Props = {
    pkg: Package;
} & Partial<RootStores>;

@inject(STORE_PROJECTS)
class PackageDownloadMenu extends React.Component<Props, object> {
    render() {
        const projectsStore = this.props.projects!;
        const { pkg } = this.props;
        const uploadEnabled: boolean =
            pkg.featureFlags?.general?.[ALPHA_CONFIG_VARIABLES.OcrPackageUploadEnabled] === 'true';

        const menu = (id: string, name: string) => {
            return (
                <Menu>
                    <Menu.Item
                        data-id={`uploaded-packages-download-pdf-${id}`}
                        key="1"
                        onClick={() => projectsStore.handleDownload(id, 'pdf')}
                    >
                        Download {name.split('.').pop()}
                    </Menu.Item>
                    {uploadEnabled && (
                        <Menu.Item
                            data-id={`uploaded-packages-download-apkg-${id}`}
                            key="2"
                            onClick={() => projectsStore.handleDownload(id, 'apkg')}
                        >
                            Download apkg
                        </Menu.Item>
                    )}
                </Menu>
            );
        };
        return (
            <Dropdown overlay={() => menu(pkg.id, pkg.name)} trigger={['click']}>
                <Tooltip title="Download">
                    <Button data-id={`uploaded-packages-download-${pkg.id}`} type="link" size="small">
                        <i className="alpha-icon lg download-icon" />
                    </Button>
                </Tooltip>
            </Dropdown>
        );
    }
}
export default PackageDownloadMenu;
