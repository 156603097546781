import { action, runInAction, computed, observable } from 'mobx';
import { message } from 'antd';
import PreviewVisualStoreBase from '../../rules/stores/PreviewVisualStoreBase';
import { ProjectApplicationDefinitionEditVisualStore } from '../../iota_applications/stores';
import { ProjectsRootVisualStore, RootStores, RouterStore } from '../../common/stores';
import { RulesStore } from '../../rules/stores';
import { BindingType, InputBinding } from '../../iota_applications/types';
import { ApplicationDefinitionsService } from '../../iota_applications/services';
import { PackageLine } from '../../common/models';
import { RuleResult } from '../../rules/types';
import { InputBindingPreviewResponse } from '../../iota_applications/types/ApplicationDefinitionPreviewBindingModel';
import { IotaAppPagesNavigation } from '../../iota_applications/routes';
import { InputGroupModel } from '../models';
import { ResultApi } from '../../common/services/AppClient';
import { ApplicationDefinitionConditionalPreviewBindingResponse } from '../types';
import ApplicationDefinitionConditionalEditStore from './ApplicationDefinitionConditionalEditStore';
import ApplicationDefinitionConditionalService from '../services/ApplicationDefinitionConditionalService';
import { ApplicationDefinitionConditional } from '../../iota_applications/types/ApplicationDefinition';
import { ApplicationDefinitionConditionalHelper } from '../misc';

type UrlParams = { appId: string; inputId?: string; index?: number; inputGroupId?: string };

type PreviewData = {
    inputGroupId?: string;
    inputBindings: InputBinding[];
};

export default class ApplicationDefinitionConditionalBindingPreviewStore extends PreviewVisualStoreBase {
    @observable
    previewData: PreviewData | null = null;

    urlParams: UrlParams | null = null;

    isEditable: boolean = false;

    appPreviewMode: boolean = false;

    @computed
    get applicationDefinitionEditStore(): ProjectApplicationDefinitionEditVisualStore | null {
        return this.stores.projectApplicationDefinitionEditUI ?? null;
    }

    @computed
    get applicationDefinitionConditionalEditStore(): ApplicationDefinitionConditionalEditStore | null {
        return this.stores.applicationDefinitionConditionalEditUI ?? null;
    }

    @computed
    get applicationDefinition() {
        return this.applicationDefinitionConditionalEditStore?.applicationDefinition ?? null;
    }

    constructor(
        private stores: RootStores,
        public projectStore: ProjectsRootVisualStore,
        public ruleStore: RulesStore,
        private routerStore: RouterStore,
        private applicationDefinitionsService: ApplicationDefinitionsService,
        private applicationDefinitionConditionalService: ApplicationDefinitionConditionalService
    ) {
        super(projectStore, ruleStore);
    }

    @action.bound
    async initUsingProjectId(projectId: string) {
        if (!this.urlParams?.appId || !this.applicationDefinitionEditStore) {
            return;
        }

        await this.rootStore.loadProjects();

        const project = this.rootStore.projects.find(p => p.id === projectId);

        if (!project) {
            return;
        }

        await this.projectStore.setupCurrentProject(project);
        await this.applicationDefinitionEditStore.loadApplication(this.urlParams.appId);

        if (!this.applicationDefinition) {
            return;
        }

        if (!this.urlParams.inputGroupId) {
            this.appPreviewMode = true;
            return;
        }

        const inputGroup = this.applicationDefinitionConditionalEditStore?.inputGroups.find(
            g => g.inputGroupId === this.urlParams?.inputGroupId
        );

        if (!inputGroup) {
            return;
        }

        const bindings = this.getInputGroupPreviewBindings(inputGroup);

        const inputBindings =
            this.urlParams.inputId && typeof this.urlParams.index === 'number'
                ? [bindings.filter(b => b.inputId === this.urlParams?.inputId)[this.urlParams.index]]
                : bindings;

        if (inputBindings) {
            this.setPreviewData({
                inputGroupId: inputGroup.inputGroupId,
                inputBindings
            });
        }
    }

    @action.bound
    async loadPreviewResults() {
        if (
            !this.applicationDefinition ||
            !this.project ||
            !this.selectedPackageIds.length ||
            !this.applicationDefinitionConditionalEditStore
        ) {
            return;
        }

        runInAction(() => {
            this.isExecuting = true;
        });

        let resp: ResultApi<ApplicationDefinitionConditionalPreviewBindingResponse[]>;

        if (this.appPreviewMode) {
            resp = await this.applicationDefinitionConditionalService.previewApplication(
                this.project.id,
                this.applicationDefinition.id,
                {
                    conditions: this.applicationDefinitionConditionalEditStore.conditions.map(c => c.dto),
                    inputGroups: this.applicationDefinitionConditionalEditStore.inputGroups.map(i => i.dto),
                    packageIds: this.selectedPackageIds
                }
            );
        } else {
            resp = await this.applicationDefinitionsService.applicationDefinitionPreviewBinding(
                this.project.id,
                this.applicationDefinition.id,
                this.previewData?.inputBindings ?? [],
                this.selectedPackageIds
            );
        }

        runInAction(() => {
            this.isExecuting = false;
        });

        if (!resp.isOk()) {
            message.error(`Error: ${resp.error.data ? resp.error.data.title : resp.error.text}`);
            return;
        }

        resp.map(result => {
            const previewResults = result.map(item => {
                return {
                    conditionName: item.condition?.name,
                    packageId: item.packageId,
                    previewResults: item.inputs.map(input => {
                        const entry = {
                            field: {} as PackageLine,
                            tokens: [],
                            tag: {
                                tagId: '',
                                values: input.previewValues.map(v => v.value)
                            },
                            resultTokens: []
                        };

                        return {
                            entries: [entry],
                            rule: {
                                name: this.getInputPreviewLabel(
                                    input,
                                    this.appPreviewMode ? item.inputGroupId : this.previewData?.inputGroupId
                                )
                            } as RuleResult
                        };
                    })
                };
            });

            runInAction(() => {
                this.previewResults = previewResults;
            });
        });
    }

    @action.bound
    goToInitialList() {
        if (this.project && this.applicationDefinition) {
            this.projectStore.navigateToEditApplicationDefinitionPage(this.project, this.applicationDefinition.id);
        }
    }

    @action.bound
    setItem(params: UrlParams) {
        this.urlParams = { ...params, index: params.index ? +params.index : undefined };
    }

    @action.bound
    setPreviewData(previewData: PreviewData) {
        this.previewData = previewData;
    }

    previewInput(inputGroupId: string, binding: InputBinding | undefined, bindingIndex: number) {
        if (!binding || !this.project || !this.applicationDefinition) {
            return;
        }

        this.appPreviewMode = false;

        this.setPreviewData({
            inputGroupId,
            inputBindings: [binding]
        });

        this.routerStore.pushToHistory(
            IotaAppPagesNavigation.ApplicationDefinitionConditionalInputBindingPreview.replace(':id', this.project.id)
                .replace(':appId', this.applicationDefinition.id)
                .replace(':inputGroupId', inputGroupId)
                .replace(':inputId', binding.inputId)
                .replace(':index', bindingIndex.toString())
        );
    }

    previewInputGroup(inputGroup: InputGroupModel) {
        if (!this.project || !this.applicationDefinition) {
            return;
        }

        this.appPreviewMode = false;

        this.setPreviewData({
            inputGroupId: inputGroup.inputGroupId,
            inputBindings: this.getInputGroupPreviewBindings(inputGroup)
        });

        this.routerStore.pushToHistory(
            IotaAppPagesNavigation.ApplicationDefinitionConditionalInputGroupPreview.replace(':id', this.project.id)
                .replace(':inputGroupId', inputGroup.inputGroupId)
                .replace(':appId', this.applicationDefinition.id)
        );
    }

    getInputGroupPreviewBindings(inputGroup: InputGroupModel) {
        return ApplicationDefinitionConditional.getInputGroupBindings(inputGroup.dto).filter(b => !!b.value);
    }

    async previewApplication() {
        if (!this.project || !this.applicationDefinition || !this.applicationDefinitionConditionalEditStore) {
            return;
        }

        this.appPreviewMode = true;

        this.routerStore.pushToHistory(
            IotaAppPagesNavigation.ApplicationDefinitionConditionalPreview.replace(':id', this.project.id).replace(
                ':appId',
                this.applicationDefinition.id
            )
        );
    }

    getInputPreviewLabel(inputBinding: InputBindingPreviewResponse, inputGroupId?: string) {
        if (!this.applicationDefinitionEditStore || !this.applicationDefinitionConditionalEditStore) {
            return inputBinding.inputId;
        }

        const input = this.applicationDefinitionConditionalEditStore.inputGroups
            .find(g => g.inputGroupId === inputGroupId)
            ?.getInputById(inputBinding.inputId);

        const metaLabel = ApplicationDefinitionConditionalHelper.getMetaLabelFromInputId(inputBinding.inputId);
        let label = input ? input.meta.name : inputBinding.inputId;

        if (input && metaLabel) {
            label = `${metaLabel} - ${label}`;
        }

        if (inputBinding.type.toLocaleLowerCase() === BindingType.constant) {
            return `${label} - Constant - ${inputBinding.value}`;
        }

        if (inputBinding.type === BindingType.tag) {
            const tag = this.rulesStore.ruleTags.find(t => t.id === inputBinding.value);
            return `${label} - Rule Tag - ${tag ? tag.name : ''}`;
        }

        if (inputBinding.type === BindingType.variable) {
            const variable = this.applicationDefinitionEditStore.environmentVariables.find(
                v => v.id === inputBinding.value
            );
            return `${label} - Environment Variable - ${variable ? variable.name : ''}`;
        }

        if (inputBinding.type === BindingType.codeRule) {
            return `${label} - Code Business Rule`;
        }

        return label;
    }

    setUrl() {
        return;
    }
}
