export default class ApplicationDefinitionConditionalHelper {
    static ParentChildBindingGroupDelimiter = '|';

    static BindingGroupIdDelimiter = ':';

    static InputIdDelimiter = '_';

    static getMetaLabelFromInputId(inputId: string): string {
        const parts = inputId.split(ApplicationDefinitionConditionalHelper.InputIdDelimiter);
        return parts.length <= 1 ? '' : parts[0].replace(/:/g, ' ').replace(/\|/g, ' - ');
    }
}
