import { Button } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Observer } from 'mobx-react';
import React from 'react';
import { Project } from '../../../common/models';
import { RulePreviewVisualStore } from '../../stores';
import { PreviewIndexToken } from '../../types';
import ProjectPackageFilter from '../ProjectPackageFilter';
import { createTreeItem } from './TreeBuilder';
import { observer } from 'mobx-react-lite';

interface PackagePreviewRunControlsProps {
    store: RulePreviewVisualStore;
    project: Project | null | undefined;
    selectedRowData: { rowId: undefined; pkgId: undefined };
    setTreeData: (data: unknown) => void;
    checkIfNodeIsExpanded: (nodeId: string) => boolean;
    checkIfNodeIsCollapsed: (nodeId: string) => boolean;
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void;
}

const PackagePreviewRunControls: React.FC<PackagePreviewRunControlsProps> = ({
    store,
    project,
    setTreeData,
    checkIfNodeIsExpanded,
    checkIfNodeIsCollapsed,
    openRowPreviewDialog,
    selectedRowData
}) => {
    const mapPreviewResultsToTreeData = () => {
        if (!store.filteredPreviewResults || !store.filteredPreviewResults.length) {
            return [];
        }

        return store.filteredPreviewResults.map(r =>
            createTreeItem(
                r,
                store,
                project,
                selectedRowData,
                checkIfNodeIsCollapsed,
                checkIfNodeIsExpanded,
                openRowPreviewDialog
            )
        );
    };

    return (
        <Sider
            id="rule-preview-package-filter-container"
            data-id="rule-preview-package-filter-container"
            width={'100%'}
            style={{ height: 'calc(100% - 82px)' }}
        >
            <ProjectPackageFilter RulePreviewUI={store} />
            <div className="rule-preview-run-container">
                <Observer>
                    {() => (
                        <Button
                            data-id="rule-preview-run-button"
                            type="primary"
                            size="large"
                            disabled={!store!.checkedPackages.length || store!.isExecuting}
                            onClick={() => store!.execute().then(() => setTreeData(mapPreviewResultsToTreeData()))}
                        >
                            Run test
                        </Button>
                    )}
                </Observer>
            </div>
        </Sider>
    );
};

export default observer(PackagePreviewRunControls);
