import { action, observable } from 'mobx';
import { isNil } from 'lodash';
import { UserBasicDetailsDto } from '../types';
import { UsersService } from '../services';

export default class UsersStore {
    @observable
    users: UserBasicDetailsDto[] = [];

    @observable
    isLoading: boolean = false;

    constructor(private usersService: UsersService) {
        this.getUsers();
    }

    @action.bound
    async getUsers() {
        try {
            this.isLoading = true;
            this.users = await this.usersService.getUsersBasicDetails();
        } finally {
            this.isLoading = false;
        }
    }

    getUserNameById(id: string) {
        const user = this.users.find(u => u.id === id);

        if (!user) {
            return id;
        }

        return !isNil(user.firstName) && !isNil(user.lastName) ? `${user.firstName} ${user.lastName}` : user.userName;
    }
}
