import { SearchOutlined } from '@ant-design/icons';
import * as React from 'react';
import { TreeItem } from 'react-sortable-tree-patch-react-17';
import { PackageLine, Project } from '../../../common/models';
import { RulePreviewVisualStore } from '../../stores';
import { IndexToken, PreviewIndexToken, PreviewResultEntry, PreviewResultWithPackageId } from '../../types';
import TableBlockPreview from '../TableBlockPreview';
import { createRootEntryChildItem } from './TreeItemTitles/RootEntryChildItem';
import { createExtraDataItemChildChildrenTitle } from './TreeItemTitles/ExtraDataItemChildChildrenTitle';
import { createTagChildNodeTitle } from './TreeItemTitles/TagChildNodeTitle';
import { createTextItemTitle } from './TreeItemTitles/TextItemTitle';
import { renderAskAlphaIcon, renderFieldValues, renderJsonPreview } from './TreeRenderHelpers';
import { convertObjectPropertiesIntoString, getType } from './Utils';

const createOutputChildNodes = (
    resultTokens: PreviewIndexToken[],
    field: PackageLine,
    checkIfNodeIsExpanded: (nodeId: string) => boolean,
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void
) => {
    let outputChildren: TreeItem[] = [];

    for (var resultToken of resultTokens) {
        let resultTokenChildren: TreeItem[] = [];

        let outputChild: TreeItem = {
            title: (
                <span data-id={`resultToken-${field.packageId}-${field.rowId}-${resultToken.token.token}`}>
                    {resultToken.token.token}
                </span>
            ),
            expanded: checkIfNodeIsExpanded(`resultToken-${field.packageId}-${field.rowId}-${resultToken.token.token}`)
        };

        for (var rTokenKey in resultToken.token) {
            if (resultToken[rTokenKey]) {
                resultTokenChildren.push({
                    title: (
                        <span
                            data-id={`resultToken-${field.packageId}-${field.rowId}-${resultToken.token.token}-${rTokenKey}`}
                        >
                            <span>
                                {rTokenKey}: {resultToken.token[rTokenKey]}
                            </span>
                        </span>
                    ),
                    expanded: checkIfNodeIsExpanded(
                        `resultToken-${field.packageId}-${field.rowId}-${resultToken.token.token}-${rTokenKey}`
                    )
                });
            }
        }
        outputChild.children = resultTokenChildren;

        let contextChild: TreeItem = {
            title: (
                <span data-id={`context-${field.packageId}-${field.rowId}-${resultToken.token.token}`}>context</span>
            ),
            expanded: checkIfNodeIsExpanded(`context-${field.packageId}-${field.rowId}-${resultToken.token.token}`),
            children: []
        };

        if (Object.getOwnPropertyNames(resultToken.context).length) {
            for (var cTokenKey in resultToken.context) {
                if (resultToken.context[cTokenKey]) {
                    contextChild.children.push({
                        title: (
                            <span
                                data-id={`context-${field.packageId}-${field.rowId}-${resultToken.token.token}-${cTokenKey}`}
                                className="context-child-children-title"
                            >
                                {cTokenKey}: {convertObjectPropertiesIntoString(resultToken.context[cTokenKey])}
                            </span>
                        )
                    });
                }
            }
        } else {
            contextChild.title = <span>context [Empty]</span>;
        }

        let extraDataChild: TreeItem = {
            title: <span data-id={`extra-${field.packageId}-${field.rowId}-${resultToken.token.token}`}>extra</span>,
            expanded: checkIfNodeIsExpanded(`extra-${field.packageId}-${field.rowId}-${resultToken.token.token}`),
            children: []
        };

        if (resultToken.extraData && Object.getOwnPropertyNames(resultToken.extraData).length) {
            for (var extraDataItemKey of Object.getOwnPropertyNames(resultToken.extraData)) {
                let extraDataItemChild: TreeItem = {
                    title: (
                        <span
                            data-id={`extra-${field.packageId}-${field.rowId}-${resultToken.token.token}-${extraDataItemKey}`}
                        >
                            {extraDataItemKey}
                        </span>
                    ),
                    expanded: checkIfNodeIsExpanded(
                        `extra-${field.packageId}-${field.rowId}-${resultToken.token.token}-${extraDataItemKey}`
                    ),
                    children: []
                };

                for (var extraDataKey of Object.getOwnPropertyNames(resultToken.extraData[extraDataItemKey])) {
                    extraDataItemChild.children.push({
                        title: (
                            <span
                                data-id={`extra-${field.packageId}-${field.rowId}-${resultToken.token.token}-${extraDataItemKey}-${extraDataKey}`}
                                className="extra-data-item-child-children-title"
                            >
                                {extraDataKey}
                            </span>
                        ),
                        expanded: true,
                        children: Object.getOwnPropertyNames(resultToken.extraData[extraDataItemKey][extraDataKey])
                            // .filter(k => !Array.isArray(resultToken.extraData[key][k]))
                            .map(k => {
                                return {
                                    title: createExtraDataItemChildChildrenTitle({
                                        field: field,
                                        resultToken: resultToken,
                                        extraDataItemKey: extraDataItemKey,
                                        extraDataKey: extraDataKey,
                                        extraDataItemChildrenKey: k,
                                        openRowPreviewDialog: openRowPreviewDialog
                                    })
                                };
                            })
                    });
                }

                extraDataChild.children.push(extraDataItemChild);
            }
        } else {
            extraDataChild.title = <span>extra [Empty]</span>;
        }

        outputChild.children.push(contextChild);
        outputChild.children.push(extraDataChild);
        outputChildren.push(outputChild);
    }

    return outputChildren;
};

const createInputChildNodes = (
    tokens: IndexToken[],
    field: PackageLine,
    checkIfNodeIsExpanded: (nodeId: string) => boolean
) => {
    let inputChildren: TreeItem[] = [];

    for (var token of tokens) {
        let tokenChildren: TreeItem[] = [];
        let inputChild: TreeItem = {
            title: <span data-id={`inputs-${field.packageId}-${field.rowId}-${token.token}`}>{token.token}</span>,
            expanded: checkIfNodeIsExpanded(`inputs-${field.packageId}-${field.rowId}-${token.token}`)
        };

        for (var tokenKey in token) {
            if (token[tokenKey] && (typeof token[tokenKey] === 'string' || typeof token[tokenKey] === 'number')) {
                tokenChildren.push({
                    title: (
                        <span data-id={`inputs-${field.packageId}-${field.rowId}-${token.token}-${tokenKey}`}>
                            <span>
                                {tokenKey}: {token[tokenKey]}
                            </span>
                        </span>
                    )
                });
            } else if (token[tokenKey]) {
                var tokenNestedChildren: TreeItem[] = [];

                for (var tKey in token[tokenKey]) {
                    if (
                        token[tokenKey][tKey] &&
                        (typeof token[tokenKey][tKey] === 'string' || typeof token[tokenKey][tKey] === 'number')
                    ) {
                        tokenNestedChildren.push({
                            title: (
                                <span
                                    data-id={`inputs-${field.packageId}-${field.rowId}-${token[tokenKey].token}-${tKey}`}
                                >
                                    <span>
                                        {tKey}: {token[tokenKey][tKey]}
                                    </span>
                                </span>
                            )
                        });
                    }
                }
                tokenChildren.push({
                    title: (
                        <span data-id={`inputs-${field.packageId}-${field.rowId}-${token.token}-${tokenKey}`}>
                            <span>{tokenKey}</span>
                        </span>
                    ),
                    children: tokenNestedChildren
                });
            }
        }
        inputChild.children = tokenChildren;
        inputChildren.push(inputChild);
    }
    return inputChildren;
};

const createTagChildNodes = (values: string[], field: PackageLine, type: 'Text' | 'Table') => {
    return values.map((t, i) => {
        let tagValJsonObject: JSX.Element | undefined = undefined;

        try {
            tagValJsonObject = type === 'Table' ? <TableBlockPreview content={t} /> : renderJsonPreview(t);
        } catch {
            // do nothing
        }

        return {
            title: createTagChildNodeTitle({ field: field, tagValJsonObject: tagValJsonObject, value: t, index: i })
        };
    });
};

export function createTreeItem(
    r: PreviewResultWithPackageId,
    store: RulePreviewVisualStore,
    proj: Project | null | undefined,
    selectedRowData: { rowId: undefined; pkgId: undefined },
    checkIfNodeIsCollapsed: (nodeId: string) => boolean,
    checkIfNodeIsExpanded: (nodeId: string) => boolean,
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void
) {
    return {
        title: (
            <span package-id={r.packageId} data-id={`package-root-${r.packageId}`}>
                {store.getPackageNameById(r.packageId)}
                {renderAskAlphaIcon(r.packageId, proj, store)}
            </span>
        ),
        expanded: checkIfNodeIsExpanded(`package-root-${r.packageId}`),
        children: r.previewResult.entries.map(e =>
            createEntryItem(e, checkIfNodeIsCollapsed, checkIfNodeIsExpanded, openRowPreviewDialog, selectedRowData)
        )
    };
}

function createEntryItem(
    entry: PreviewResultEntry,
    checkIfNodeIsCollapsed: (nodeId: string) => boolean,
    checkIfNodeIsExpanded: (nodeId: string) => boolean,
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void,
    selectedRowData: { rowId: undefined; pkgId: undefined }
) {
    let jsonObjPreview = getJsonObjPreview(entry);
    let outputChildren = createOutputChildNodes(
        entry.resultTokens,
        entry.field,
        checkIfNodeIsExpanded,
        openRowPreviewDialog
    );
    let inputChildren = createInputChildNodes(entry.tokens, entry.field, checkIfNodeIsExpanded);
    let fieldChildren = createFieldChildren(entry.field);
    let tagChildren = createTagChildNodes(entry.tag.values, entry.field, getType(entry));

    return {
        title: createRootEntryChildItem({ entry, jsonObjPreview, selectedRowData }),
        expanded: checkIfNodeIsExpanded(`entry-${entry.field.packageId}-${entry.field.rowId}`),
        children: createEntryChildren(
            entry,
            jsonObjPreview,
            tagChildren,
            fieldChildren,
            inputChildren,
            outputChildren,
            checkIfNodeIsCollapsed,
            checkIfNodeIsExpanded,
            openRowPreviewDialog
        )
    };
}

function getJsonObjPreview(entry: PreviewResultEntry) {
    const type = getType(entry);
    try {
        return type === 'Table' ? (
            <TableBlockPreview content={entry.field.normalizedText} />
        ) : (
            renderJsonPreview(entry.field.normalizedText)
        );
    } catch {
        return undefined;
    }
}

function createFieldChildren(field: PackageLine) {
    let fieldChildren = [];
    for (var key in field) {
        if (field[key]) {
            fieldChildren.push({
                title: <>{renderFieldValues(field, key)}</>
            });
        }
    }
    return fieldChildren;
}

function createEntryChildren(
    e: PreviewResultEntry,
    jsonObjPreview: JSX.Element | undefined,
    tagChildren: {
        title: JSX.Element;
    }[],
    fieldChildren: {
        title: JSX.Element;
    }[],
    inputChildren: TreeItem[],
    outputChildren: TreeItem[],
    checkIfNodeIsCollapsed: (nodeId: string) => boolean,
    checkIfNodeIsExpanded: (nodeId: string) => boolean,
    openRowPreviewDialog: (content: string, resultTokens: PreviewIndexToken[]) => void
) {
    return [
        {
            title: <span data-id={`tag-value-${e.field.packageId}-${e.field.rowId}`}>tag value</span>,
            expanded: !checkIfNodeIsCollapsed(`tag-value-${e.field.packageId}-${e.field.rowId}`),
            children: tagChildren
        },
        {
            title: (
                <span data-id={`debug-${e.field.packageId}-${e.field.rowId}`}>
                    <SearchOutlined /> debug
                </span>
            ),
            expanded: checkIfNodeIsExpanded(`debug-${e.field.packageId}-${e.field.rowId}`),
            children: [
                {
                    title: <span data-id={`fields-root-${e.field.packageId}-${e.field.rowId}}`}>field</span>,
                    expanded: checkIfNodeIsExpanded(`fields-root-${e.field.packageId}-${e.field.rowId}}`),
                    children: fieldChildren
                },
                {
                    title: <span data-id={`input-root-${e.field.packageId}-${e.field.rowId}}`}>input</span>,
                    expanded: checkIfNodeIsExpanded(`input-root-${e.field.packageId}-${e.field.rowId}}`),
                    children: inputChildren
                },
                {
                    title: <span data-id={`output-root-${e.field.packageId}-${e.field.rowId}}`}>output</span>,
                    expanded: checkIfNodeIsExpanded(`output-root-${e.field.packageId}-${e.field.rowId}}`),
                    children: outputChildren
                }
            ]
        },
        {
            title: <span data-id={`text-root-${e.field.packageId}-${e.field.rowId}`}>text</span>,
            expanded: !checkIfNodeIsCollapsed(`text-root-${e.field.packageId}-${e.field.rowId}`),
            children: [
                {
                    title: createTextItemTitle({
                        entry: e,
                        jsonObjPreview: jsonObjPreview,
                        openRowPreviewDialog: openRowPreviewDialog
                    })
                }
            ]
        }
    ];
}
