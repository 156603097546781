import { RootStores } from '../../common/stores';
import ProjectApplicationDefinitionsVisualStore from './ProjectApplicationDefinitionsVisualStore';
import ProjectApplicationDefinitionEditVisualStore from './ProjectApplicationDefinitionEditVisualStore';
import { ApplicationDefinitionsService, EnvironmentVariablesService } from '../services';
import {
    STORE_PROJECT_APPLICATION_DEFINITIONS,
    STORE_PROJECT_APPLICATION_DEFINITION_EDIT,
    STORE_PROJECT_APPLICATION_DEFINITION_TREE,
    STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW
} from './constants';
import {
    STORE_PROJECTS_ROOT_UI,
    STORE_ERRORS,
    STORE_ALPHA_CONFIG,
    STORE_ROUTER,
    STORE_USERS
} from '../../common/constants';
import { STORE_RULES, STORE_TAGS_GROUP } from '../../rules/constants';
import ProjectApplicationDefinitionsTreeVisualStore from './ProjectApplicationDefinitionsTreeVisualStore';
import BindingPreviewVisualStore from './BindingPreviewVisualStore';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';

export { default as ProjectApplicationDefinitionEditVisualStore } from './ProjectApplicationDefinitionEditVisualStore';
export { default as ProjectApplicationDefinitionsVisualStore } from './ProjectApplicationDefinitionsVisualStore';
export { default as ProjectApplicationDefinitionsTreeVisualStore } from './ProjectApplicationDefinitionsTreeVisualStore';

export function injectIotaAppStores(obj: RootStores) {
    const service = new ApplicationDefinitionsService();
    const envSevice = new EnvironmentVariablesService();
    const adminService = new GlobalAdministrationService();

    obj[STORE_PROJECT_APPLICATION_DEFINITION_TREE] = new ProjectApplicationDefinitionsTreeVisualStore(
        obj[STORE_PROJECTS_ROOT_UI],
        service
    );
    obj[STORE_PROJECT_APPLICATION_DEFINITIONS] = new ProjectApplicationDefinitionsVisualStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_PROJECT_APPLICATION_DEFINITION_TREE],
        obj[STORE_ALPHA_CONFIG],
        service,
        adminService
    );
    obj[STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW] = new BindingPreviewVisualStore(
        obj,
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_RULES],
        service
    );
    obj[STORE_PROJECT_APPLICATION_DEFINITION_EDIT] = new ProjectApplicationDefinitionEditVisualStore(
        obj[STORE_PROJECTS_ROOT_UI],
        service,
        obj[STORE_RULES],
        obj[STORE_TAGS_GROUP],
        envSevice,
        obj[STORE_ERRORS],
        obj[STORE_PROJECT_APPLICATION_DEFINITION_TREE],
        obj[STORE_PROJECT_APPLICATION_DEFINITIONS],
        obj[STORE_ROUTER],
        obj[STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW],
        obj[STORE_USERS]
    );
}

export type IotaAppStores = {
    [STORE_PROJECT_APPLICATION_DEFINITIONS]?: ProjectApplicationDefinitionsVisualStore;
    [STORE_PROJECT_APPLICATION_DEFINITION_EDIT]?: ProjectApplicationDefinitionEditVisualStore;
    [STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW]?: BindingPreviewVisualStore;
};
