import { observable, action } from 'mobx';
import { flatten } from 'lodash';
import { BindingGroupDto } from '../types';
import { InputModel } from '.';
import { InputBinding } from '../../iota_applications/types';

export default class BindingGroupModel {
    @observable
    name: string;

    @observable
    enabled: boolean;

    @observable
    editable: boolean = false;

    @observable
    inputs: InputModel[] = [];

    @observable
    childBindingGroups: BindingGroupModel[] = [];

    @observable
    openChildBindingGroups: string[] = [];

    readonly bindingGroupId: string;

    constructor(bindingGroupId: string, name: string, enabled: boolean) {
        this.bindingGroupId = bindingGroupId;
        this.name = name;
        this.enabled = enabled;
    }

    @action.bound
    setName(name: string) {
        this.name = name.trim();
    }

    @action.bound
    setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    @action.bound
    setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action.bound
    setInputs(inputs: InputModel[]) {
        this.inputs = inputs;
    }

    @action.bound
    setChildBindingGroups(childBindingGroups: BindingGroupModel[]) {
        this.childBindingGroups = childBindingGroups;
    }

    @action.bound
    setOpenChildBindingGroups(openChildBindingGroups: string[]) {
        this.openChildBindingGroups = openChildBindingGroups;
    }

    getInputById(inputId: string): InputModel | undefined {
        return this.inputs.find(i => i.inputId === inputId);
    }

    get hasBindings(): boolean {
        const hasBindingsInGroup = (inputs: InputModel[], childBindingGroups: BindingGroupModel[]): boolean => {
            const hasBindingsInCurrentGroup = this.getNonEmptyBindings(inputs).length > 0;

            return (
                hasBindingsInCurrentGroup ||
                childBindingGroups.some(group => hasBindingsInGroup(group.inputs, group.childBindingGroups))
            );
        };

        return hasBindingsInGroup(this.inputs, this.childBindingGroups);
    }

    get dto(): BindingGroupDto {
        return {
            bindingGroupId: this.bindingGroupId,
            name: this.name,
            enabled: this.enabled,
            bindings: this.getNonEmptyBindings(this.inputs),
            childBindingGroups: this.childBindingGroups.filter(g => g.hasBindings).map(g => g.dto)
        };
    }

    private getNonEmptyBindings(inputs: InputModel[]): InputBinding[] {
        return flatten(inputs.map(i => i.bindings)).filter(b => !!b.value);
    }
}
