import _ from 'lodash';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { Index } from 'react-virtualized';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, merge, switchMap } from 'rxjs/operators';
import { ProjectsRootVisualStore, UsersStore } from '../../common/stores';
import { Pager, PAGE_SIZE } from '../../common/stores/Pager';
import { SearchSessionsAutoCompleteSourceItem } from '../../iota_sessions/types';
import { SEARCH_DEBOUNCE_TIME } from '../../project_management/stores/UploadedPackagesVisualStore';
import IotaAuditService from '../services/IotaAuditService';
import { IotaAuditResponse, IotaAuditResult, AuditInput } from '../types/IotaAuditResult';
const COLUMN_HEIGHT = 48;

export default class IotaAuditVisualStore {
    @observable
    selectedSessionIndex: number | undefined;

    @observable
    pager: Pager<IotaAuditResult>;

    @observable
    isLoading: boolean;

    @observable
    searchTerm: string = '';

    @observable
    currentDates: string[] = [];

    @observable
    autocompleteSource: SearchSessionsAutoCompleteSourceItem[] = [];

    @observable
    loadedIotaProjectPayloads: { [sessionId: string]: AuditInput[] } = {};

    @observable
    expandedInputs: { key: string; size: number }[] = [];

    currentSelectedPackage: string;

    @computed
    get auditEvents(): IotaAuditResult[] {
        return this.pager.data;
    }

    @computed
    get users() {
        return this.usersStore.users;
    }

    private searchSubject: Subject<string | null>;
    private searchResult: Observable<IotaAuditResponse>;
    private searchForAutocompleteCallServer: (s: string) => Promise<void>;

    constructor(
        public rootStore: ProjectsRootVisualStore,
        private auditService: IotaAuditService,
        private usersStore: UsersStore
    ) {
        this.searchSubject = new Subject<string>();
        this.pager = new Pager();
        // this.rootStore.projectsStore.sessionChanges.pipe(map(x => x.projectId)).subscribe((p) => {
        //     this.updateSessions(p);
        // });
        this.searchResult = this.searchSubject.pipe(
            debounceTime(SEARCH_DEBOUNCE_TIME),
            merge(this.pager.nextPageRequest.let(map(() => this.searchTerm))),
            switchMap(x => Observable.fromPromise(this.searchInAudit(x)))
        );

        this.searchResult.subscribe(r => this.setFilteredData(r));
        reaction(
            () => this.rootStore.currentProject,
            p => {
                if (p) {
                    this.performSearch();
                }
            },
            {
                fireImmediately: true
            }
        );
        this.searchForAutocompleteCallServer = _.debounce(this.searchForAutocompleteImp, 200);
    }

    get currentProject() {
        return this.rootStore.currentProject;
    }

    @action.bound
    setselectedSessionIndex(sessionId: number | undefined) {
        this.selectedSessionIndex = sessionId;
    }

    @action.bound
    async searchForAutocomplete(term: string) {
        this.searchTerm = term;
        return this.searchForAutocompleteCallServer(this.searchTerm);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async handleSessionClick(params: any) {
        if (params.index === this.selectedSessionIndex) {
            this.setselectedSessionIndex(undefined);
        } else {
            this.expandedInputs = [];
            if (!this.loadedIotaProjectPayloads || !this.loadedIotaProjectPayloads[params.rowData.id]) {
                await this.loadIotaProjectPayloads(params.rowData.sessionId);
            }

            this.setselectedSessionIndex(params.index);
        }
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDates(dates: any, dateStrings: [string, string]) {
        if (!dates.length) {
            this.currentDates = [];
        } else {
            this.currentDates = dateStrings;
        }
        this.performSearch(this.searchTerm);
    }

    @action
    async loadIotaProjectPayloads(sessionId: string) {
        const res = await this.auditService.getIotaProjectPayloads(sessionId, this.currentSelectedPackage);
        runInAction(() => {
            this.loadedIotaProjectPayloads[sessionId] = res;
        });
    }

    @action
    clearFilter() {
        this.searchTerm = '';
        this.currentDates = [];
        this.performSearch();
    }

    @action
    loadNextPage() {
        this.pager.loadNextPage();
    }

    @action
    setFilteredData(response: IotaAuditResponse) {
        const { lines, total } = response;
        this.pager.setData(lines, total);
        this.isLoading = false;
    }

    @action.bound
    performSearch(term: string = '') {
        this.currentSelectedPackage = term;
        this.searchTerm = term;
        this.pager.resetPager();
        this.isLoading = true;
        this.searchSubject.next(this.searchTerm);
    }

    @action
    private async searchForAutocompleteImp(term: string) {
        const request = {
            projectId: this.rootStore.currentProject!.id,
            searchTerm: term,
            page: 0,
            pageSize: 15,
            dates: this.currentDates
        };

        const result = await this.auditService.searchAudit(request);

        runInAction(() => {
            if (!result?.lines) {
                this.autocompleteSource = [];
                return;
            }

            const uniqueLines = _.uniqBy(result.lines, 'packageId');
            this.autocompleteSource = uniqueLines.map(l => ({
                packageName: l.packageName
            }));
        });
    }

    getRowHeight = (info: Index) => {
        return info.index === this.selectedSessionIndex
            ? COLUMN_HEIGHT + this.getRowDetailsHeightForSession() - 13
            : COLUMN_HEIGHT;
    };

    getUserById(id: string) {
        return this.usersStore.getUserNameById(id);
    }

    handleInputExpand(expanded: boolean, record: AuditInput) {
        if (expanded) {
            this.expandedInputs.push({
                key: `${record.field!}-${record.auditInputsMeta[0].date}`,
                size: record.auditInputsMeta.length
            });
        } else {
            const index = this.expandedInputs.findIndex(
                i => i.key === `${record.field!}-${record.auditInputsMeta[0].date}`
            );
            this.expandedInputs.splice(index, 1);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private searchInAudit(term: string | null): Promise<any> {
        const request = {
            projectId: this.rootStore.currentProject!.id,
            searchTerm: term,
            page: this.pager.currentPage,
            pageSize: PAGE_SIZE,
            dates: this.currentDates
        };

        return this.auditService.searchAudit(request);
    }

    private getRowDetailsHeightForSession() {
        const sessionId = this.auditEvents[this.selectedSessionIndex!].sessionId;
        if (this.loadedIotaProjectPayloads[sessionId] && this.loadedIotaProjectPayloads[sessionId].length > 0) {
            const expandedInputRowCount = this.expandedInputs.map(i => i.size).reduce((a, b) => a + b, 0);
            return (
                (COLUMN_HEIGHT - 1) * (this.loadedIotaProjectPayloads[sessionId].length + 1) +
                (COLUMN_HEIGHT - 1) * 2 -
                81 +
                (expandedInputRowCount * (COLUMN_HEIGHT - 1) + expandedInputRowCount * (COLUMN_HEIGHT - 1))
            );
        } else {
            return COLUMN_HEIGHT * 4.5;
        }
    }
}
