import React from 'react';
import { PreviewResultEntry } from '../../types';

export function addNewLines(text: string) {
    const res = [...text].map((c, i) => {
        if (c === '\n') {
            return (
                <span key={i} style={{ color: '#69b5e7' }}>
                    {'\\n'}
                </span>
            );
        } else if (c === '\t') {
            return (
                <span key={i} style={{ color: '#69b5e7' }}>
                    {'\\t'}
                </span>
            );
        } else {
            return c;
        }
    });
    return <>{res}</>;
}

export function convertObjectPropertiesIntoString(obj: object) {
    let result = '[';
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const element = obj[key];
            result += `${key}: ${element} `;
        }
    }

    return result + ']';
}

export function getType(entry: PreviewResultEntry) {
    return entry.resultTokens.length > 0 ? entry.resultTokens[entry.resultTokens.length - 1].token.type : 'Text';
}
