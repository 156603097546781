import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input, FormInstance, Select, Tooltip } from 'antd';
import ProjectApplicationDefinitionEditVisualStore from '../stores/ProjectApplicationDefinitionEditVisualStore';
import { InputBinding, InputMeta, BindingType } from '../types';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import CodeBusinessRulePreview from './CodeBusinessRulePreview';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import { AppstoreAddOutlined } from '@ant-design/icons';
import SelectMultipleTagsDialog from './SelectMultipleTagsDialog';

const Option = Select.Option;
const InputGroup = Input.Group;
const OptGroup = Select.OptGroup;

type SelectMultipleTagsDialogProps = {
    add: (defaultValue?: unknown, insertIndex?: number | undefined) => void;
    remove: (index: number | number[]) => void;
    inputId: string;
};

interface Props {
    store: ProjectApplicationDefinitionEditVisualStore;
    form: FormInstance;
    input: InputMeta;
    inputFormKey: string;
    bindings: InputBinding[];
    index: number;
    handleInputValuesChange: (inputId: string) => void;
    previewInput: (inputId: string, bindingIndex: number) => void;
}

const ApplicationInputBindingsRenderer: React.FC<Props> = ({
    store,
    form,
    input,
    inputFormKey,
    bindings,
    index,
    handleInputValuesChange,
    previewInput
}) => {
    // value uesed purely for triggereing component rerendering
    const [disabledTag, setDisabledTag] = React.useState(false);
    const [isSelectMultipleTagsDialogVisible, setIsSelectMultipleTagsDialogVisible] = React.useState(false);
    const [selectMultipleTagsDialogProps, setSelectMultipleTagsDialogProps] =
        React.useState<SelectMultipleTagsDialogProps>();

    const includeDisabledTag = (binding: InputBinding, inputIndex: number) => {
        if (binding.type === 'tag') {
            const val = form.getFieldValue(inputFormKey);

            return store.isTagDisabled(val[inputIndex].value as string);
        }
        return false;
    };

    const handleChange = (inputIndex: number) => {
        const val = form.getFieldValue(inputFormKey);

        const obj = Object.create(null);
        val[inputIndex].value = null;
        obj[inputFormKey] = val;
        form.setFieldsValue(obj);
        handleInputValuesChange(inputFormKey);
    };

    const getValidationRules = (inputMeta: InputMeta) => {
        return inputMeta.validation && inputMeta.validation.required
            ? [
                  {
                      required: inputMeta.validation && (inputMeta.validation.required as boolean),
                      message: 'Please input binding information'
                  },
                  () => ({
                      validator(_: {}, value: string | null) {
                          if (value && value.trim() === '') {
                              return Promise.reject('Do not leave empty values.');
                          }
                          return Promise.resolve();
                      }
                  })
              ]
            : [];
    };

    const getDropdownForTypes = (isDisabled: boolean, i: number) => {
        return (
            <SelectWhenScroll
                disabled={isDisabled}
                data-id={`iota-inputs-type-${inputFormKey}-${i}`}
                style={{ width: '100%' }}
                onChange={() => handleChange(i)}
            >
                <Option value={BindingType.constant} data-id={`iota-inputs-type-${inputFormKey}-${i}-constant`}>
                    Constant
                </Option>
                <Option value={BindingType.tag} data-id={`iota-inputs-type-${inputFormKey}-${i}-tag`}>
                    Rule Tag
                </Option>
                <Option value={BindingType.variable} data-id={`iota-inputs-type-${inputFormKey}-${i}-variable`}>
                    Environment Variable
                </Option>
                <Option value={BindingType.codeRule} data-id={`iota-inputs-type-${inputFormKey}-${i}-coderule`}>
                    Code Business Rule
                </Option>
            </SelectWhenScroll>
        );
    };

    const getValueInput = (binding: InputBinding, inputIndex: number, subIndex: number, isInputDisabled?: boolean) => {
        let valueInput = (
            <Input disabled={isInputDisabled} data-id={`iota-inputs-value-${inputFormKey}-${inputIndex}`} />
        );

        if (binding.type === BindingType.tag) {
            const groups = store.tagGroups;
            valueInput = (
                <SelectWhenScroll
                    disabled={isInputDisabled}
                    data-id={`iota-inputs-value-${inputFormKey}-${inputIndex}`}
                    style={{ width: '100%' }}
                    showSearch
                    onChange={() => setDisabledTag(!disabledTag)}
                >
                    {groups.map(g => (
                        <OptGroup key={g.id} label={g.name}>
                            {store.getTagsForGroup(g.id).map(x => (
                                <Option
                                    data-id={`iota-inputs-value-${inputFormKey}-${inputIndex}-${x}`}
                                    key={x}
                                    value={x}
                                >
                                    {x}
                                </Option>
                            ))}
                        </OptGroup>
                    ))}
                </SelectWhenScroll>
            );
        }

        if (binding.type === BindingType.variable) {
            valueInput = (
                <SelectWhenScroll
                    disabled={isInputDisabled}
                    data-id={`iota-inputs-value-${inputFormKey}-${inputIndex}`}
                    style={{ width: '100%' }}
                >
                    {store.environmentVariables.map(x => (
                        <Option
                            data-id={`iota-inputs-value-${inputFormKey}-${inputIndex}-${x.id}`}
                            key={x.id}
                            value={x.id}
                        >
                            {x.name}
                        </Option>
                    ))}
                </SelectWhenScroll>
            );
        }

        if (binding.type === BindingType.codeRule) {
            valueInput = (
                <CodeBusinessRulePreview
                    isDisabled={!!isInputDisabled}
                    code={binding.value as string}
                    dataId={`iota-inputs-value-${inputFormKey}-${index}`}
                    onSave={c => {
                        const vals = form.getFieldValue(inputFormKey);
                        vals[subIndex].value = c;
                        const obj = Object.create(null);
                        obj[inputFormKey] = vals;
                        form.setFieldsValue(obj);
                        handleInputValuesChange(inputFormKey);
                    }}
                />
            );
        }

        return valueInput;
    };

    return (
        <>
            {selectMultipleTagsDialogProps && (
                <SelectMultipleTagsDialog
                    isVisible={isSelectMultipleTagsDialogVisible}
                    setIsVisible={setIsSelectMultipleTagsDialogVisible}
                    store={store}
                    add={selectMultipleTagsDialogProps.add}
                    remove={selectMultipleTagsDialogProps.remove}
                    inputId={selectMultipleTagsDialogProps.inputId}
                    form={form}
                />
            )}

            <Form.List key={`${inputFormKey}-${index}`} name={inputFormKey}>
                {(fields, { add, remove }) => {
                    return (
                        <InputGroup key={`${inputFormKey}-${index}`} size="default" style={{ marginBottom: 20 }}>
                            {fields.map((field, i) => {
                                const binding = bindings[i];
                                return (
                                    <div
                                        key={`${index}-${i}`}
                                        className={i !== 0 ? 'no-label binding-container' : 'binding-container'}
                                    >
                                        <Form.Item
                                            colon={false}
                                            label={
                                                <Tooltip title={input.name}>
                                                    <div className="binding-label">{input.name}</div>
                                                </Tooltip>
                                            }
                                            className={
                                                input.validation && input.validation.required ? 'label-required' : ''
                                            }
                                            style={{ marginBottom: 5 }}
                                        >
                                            <Form.Item
                                                style={{ marginBottom: 5 }}
                                                {...field}
                                                key={`${index}-${i}-type`}
                                                name={[field.name, 'type']}
                                                rules={getValidationRules(input)}
                                            >
                                                {getDropdownForTypes(store.isInputDisabled || false, i)}
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                key={`${index}-${i}-value`}
                                                name={[field.name, 'value']}
                                                rules={getValidationRules(input)}
                                                style={{ marginBottom: 5, marginLeft: 10 }}
                                            >
                                                {getValueInput(binding, index, i, store.isInputDisabled)}
                                            </Form.Item>

                                            <HasPermission
                                                entityId={store.currentProject?.id}
                                                permissionClaim={AppPermissions.CanEditIotaApplications}
                                            >
                                                <span>
                                                    <Button
                                                        data-id={`iota-inputs-remove-${inputFormKey}-${i}`}
                                                        type="link"
                                                        disabled={store.isInputDisabled || fields.length === 1}
                                                        onClick={() => remove(field.name)}
                                                        size="small"
                                                    >
                                                        <i className="alpha-icon lg minus-icon" />
                                                    </Button>
                                                    {i === fields.length - 1 && (
                                                        <Button
                                                            data-id={`iota-inputs-add-${inputFormKey}`}
                                                            type="link"
                                                            disabled={store.isInputDisabled}
                                                            onClick={() =>
                                                                add({
                                                                    type: BindingType.constant,
                                                                    value: ''
                                                                })
                                                            }
                                                            size="small"
                                                        >
                                                            <i className="alpha-icon lg plus-icon" />
                                                        </Button>
                                                    )}
                                                    {i === 0 && (
                                                        <>
                                                            <Tooltip title="Add Tags">
                                                                <Button
                                                                    data-id={`iota-inputs-add-tags-${inputFormKey}`}
                                                                    type="link"
                                                                    size="small"
                                                                    style={{
                                                                        width: 40,
                                                                        verticalAlign: 'text-bottom',
                                                                        marginBottom: 2,
                                                                        color: '#858585'
                                                                    }}
                                                                    disabled={store.isInputDisabled}
                                                                    onClick={() => {
                                                                        setSelectMultipleTagsDialogProps({
                                                                            add,
                                                                            remove,
                                                                            inputId: input.id
                                                                        });
                                                                        setIsSelectMultipleTagsDialogVisible(true);
                                                                    }}
                                                                >
                                                                    <AppstoreAddOutlined />
                                                                </Button>
                                                            </Tooltip>
                                                        </>
                                                    )}

                                                    {typeof binding.value === 'string' &&
                                                    binding.value.trim().length ? (
                                                        <Tooltip title="Preview Input">
                                                            <Button
                                                                type="link"
                                                                size="small"
                                                                onClick={() => previewInput(input.id, i)}
                                                            >
                                                                <i className="alpha-icon lg play-icon green" />
                                                            </Button>
                                                        </Tooltip>
                                                    ) : null}
                                                </span>
                                            </HasPermission>

                                            <div className="iota-disabled-rule-tag">
                                                {disabledTag !== undefined && includeDisabledTag(bindings[i], i)
                                                    ? 'All Rules under this Tag are disabled'
                                                    : ''}
                                            </div>
                                        </Form.Item>
                                    </div>
                                );
                            })}
                        </InputGroup>
                    );
                }}
            </Form.List>
        </>
    );
};

export default observer(ApplicationInputBindingsRenderer);
