import { observable, action, computed } from 'mobx';
import { flatten } from 'lodash';
import { ApplicationDefinitionConditionalEditStore } from '../stores';
import { InputGroupDto, BindingGroupDto, LayoutNodeDto } from '../types';
import { BindingGroupModel, InputModel } from '.';
import { InputBinding } from '../../iota_applications/types';
import { ApplicationDefinitionConditionalHelper } from '../misc';

export default class InputGroupModel {
    @observable
    name: string;

    @observable
    enabled: boolean;

    @observable
    isDefault: boolean;

    @observable
    editable: boolean = false;

    @observable
    bindingGroups: BindingGroupModel[] = [];

    @observable
    layoutSettingsId: string | null = null;

    @observable
    openBindingGroupIds: string[] = [];

    @computed
    get editableBindingGroup() {
        return this.bindingGroups.find(g => g.editable);
    }

    @computed
    get tabKey() {
        return `${this.inputGroupId}_${this.isDefault}`;
    }

    @computed
    get layoutSettings() {
        return this.store.applicationDefinitionLayoutStructure?.layoutSettings.find(
            s => s.id === this.layoutSettingsId
        );
    }

    get dto(): InputGroupDto {
        return {
            inputGroupId: this.inputGroupId,
            name: this.name,
            enabled: this.enabled,
            isDefault: this.isDefault,
            layoutSettingsId: this.layoutSettingsId,
            bindingGroups: this.bindingGroups.filter(g => g.hasBindings).map(g => g.dto)
        };
    }

    readonly inputGroupId: string;

    constructor(
        private readonly store: ApplicationDefinitionConditionalEditStore,
        data: InputGroupDto
    ) {
        this.inputGroupId = data.inputGroupId;
        this.name = data.name;
        this.enabled = data.enabled;
        this.isDefault = data.isDefault;
        this.layoutSettingsId = data.layoutSettingsId;

        const hiddenSections = this.layoutSettings?.hiddenSections ?? [];
        const filteredSections = this.store.applicationDefinitionSectionNames.filter(s => !hiddenSections.includes(s));

        this.bindingGroups = filteredSections.map(section => this.createBindingGroup(section, data.bindingGroups));
    }

    @action.bound
    update(data: Partial<InputGroupDto>) {
        this.name = data.name ? data.name.trim() : this.name;
        this.enabled = data.enabled ?? this.enabled;
        this.isDefault = data.isDefault ?? this.isDefault;
        this.layoutSettingsId = data.layoutSettingsId ?? this.layoutSettingsId;
        this.store.setIsDataChanged(true);
    }

    @action.bound
    setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action.bound
    setOpenBindingGroupIds(openBindingGroupIds: string[]) {
        this.openBindingGroupIds = openBindingGroupIds;
    }

    getInputById(inputId: string) {
        const searchInBindingGroups = (bindingGroups: BindingGroupModel[]): InputModel | undefined => {
            for (const bindingGroup of bindingGroups) {
                const input = bindingGroup.getInputById(inputId);

                if (input) {
                    return input;
                }

                const childInput = searchInBindingGroups(bindingGroup.childBindingGroups);

                if (childInput) {
                    return childInput;
                }
            }

            return undefined;
        };

        return searchInBindingGroups(this.bindingGroups);
    }

    delete() {
        this.store.removeInputGroup(this);
        this.store.setIsDataChanged(true);
    }

    private createBindingGroup(section: string, bindingGroups: BindingGroupDto[]): BindingGroupModel {
        const bindingGroupId = section.replace(/\s+/g, '');
        const existingBindingGroup = bindingGroups.find(g => g.bindingGroupId === bindingGroupId);

        const bindingGroup = new BindingGroupModel(
            bindingGroupId,
            existingBindingGroup?.name ?? section,
            existingBindingGroup?.enabled ?? true
        );

        if (
            !this.layoutSettings?.layoutNodes.length ||
            this.layoutSettings.sectionsWithDefaultLayout.includes(section)
        ) {
            bindingGroup.setInputs(this.createInputs(section, existingBindingGroup?.bindings));
            return bindingGroup;
        }

        const childBindingGroups = this.layoutSettings.layoutNodes.map(layoutNode =>
            this.createChildBindingGroups(section, layoutNode, existingBindingGroup?.childBindingGroups ?? [])
        );

        bindingGroup.setChildBindingGroups(flatten(childBindingGroups));

        return bindingGroup;
    }

    private createChildBindingGroups(
        section: string,
        layoutNode: LayoutNodeDto,
        existingChildBindingGroups: BindingGroupDto[],
        parentBindingGroupId?: string
    ): BindingGroupModel[] {
        return Array.from({ length: layoutNode.count }, (_, i) => {
            const singleNode = layoutNode.count === 1;
            const instanceNumber = i + 1;
            const bindingGroupName = singleNode ? layoutNode.name : `${layoutNode.name} ${instanceNumber}`;
            const bindingGroupId = singleNode
                ? layoutNode.id
                : `${layoutNode.id}${ApplicationDefinitionConditionalHelper.BindingGroupIdDelimiter}${instanceNumber}`;

            const existingChildBindingGroup = existingChildBindingGroups.find(g => g.bindingGroupId === bindingGroupId);

            const bindingGroup = new BindingGroupModel(
                bindingGroupId,
                existingChildBindingGroup?.name ?? bindingGroupName,
                existingChildBindingGroup?.enabled ?? true
            );

            if (layoutNode.childNodes.length) {
                const childChildBindingGroups = layoutNode.childNodes.map(childLayoutNode =>
                    this.createChildBindingGroups(
                        section,
                        childLayoutNode,
                        existingChildBindingGroup?.childBindingGroups ?? [],
                        bindingGroupId
                    )
                );

                bindingGroup.setChildBindingGroups(flatten(childChildBindingGroups));
            } else {
                bindingGroup.setInputs(
                    this.createInputs(
                        section,
                        existingChildBindingGroup?.bindings,
                        parentBindingGroupId
                            ? `${parentBindingGroupId}${ApplicationDefinitionConditionalHelper.ParentChildBindingGroupDelimiter}${bindingGroup.bindingGroupId}`
                            : bindingGroup.bindingGroupId
                    )
                );
            }

            return bindingGroup;
        });
    }

    private createInputs(section: string, existingBindings?: InputBinding[], idPrefix?: string): InputModel[] {
        const inputsToHide =
            this.layoutSettings?.sectionsHiddenInputs.find(s => s.section === section)?.inputsToHide ?? [];

        return this.store.applicationDefinitionInputs
            .filter(input => !inputsToHide.includes(input.id) && input.section === section)
            .map(inputMeta => {
                const inputId = idPrefix
                    ? `${idPrefix}${ApplicationDefinitionConditionalHelper.InputIdDelimiter}${inputMeta.id}`
                    : inputMeta.id;

                const bindings = existingBindings?.filter(b => b.inputId === inputId) ?? [];

                return new InputModel(inputId, inputMeta, bindings);
            });
    }
}
