import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Input } from 'antd';
import { ProjectsStore, RouterStore, ProjectsRootVisualStore } from '../../modules/common/stores';
import './ProjectsSearch.less';

type Props = {
    routerStore: RouterStore;
    projectsStore: ProjectsStore;
    projectsVisualStore: ProjectsRootVisualStore;
};

const ProjectsSearch: React.FC<Props> = ({ routerStore, projectsStore, projectsVisualStore }) => {
    if (routerStore.sideCollapsed) {
        return null;
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        projectsStore.setSearchTerm(e.target.value);
    };

    return (
        <div className="projects-search-container">
            <Input
                className={`projects-search-input ${projectsVisualStore.currentProject?.color ?? 'default'}`}
                value={projectsStore.searchTerm}
                placeholder="Search project..."
                onChange={handleSearch}
                allowClear
                suffix={<i className="alpha-icon md search-icon" />}
            />
        </div>
    );
};

export default observer(ProjectsSearch);
